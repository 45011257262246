import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const GET_ALL_REPORTS_SUCCESS = createAction('reports/GET_ALL_REPORTS.SUCCESS');
export const fetchAllReports = (filterDate, page, offset, filterByRole, nameFilter) => async (dispatch) => {
  try {
    const roleFilter = filterByRole && filterByRole !== 'ALL' ? `&role=${filterByRole}` : '';
    const filterByName = nameFilter ? `&name=${nameFilter}` : '';
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `standupreports?page=${page}&offset=${offset}&date=${filterDate}${roleFilter}${filterByName}`,
    });
    dispatch(GET_ALL_REPORTS_SUCCESS(data));
  } catch (err) {}
};

export const CREATE_MEETING_REPORT_SUCCESS = createAction('reports/CREATE_MEETING_REPORT.SUCCESS');
export const CREATE_MEETING_REPORT_FAILURE = createAction('reports/CREATE_MEETING_REPORT.FAILURE');
export const createMeetingReport = (reportData, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: 'standupreports',
      method: 'POST',
      query: reportData,
    });
    dispatch(CREATE_MEETING_REPORT_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(CREATE_MEETING_REPORT_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const DELETE_OWN_REPORT_SUCCESS = createAction('reports/DELETE_OWN_REPORT.SUCCESS');
export const deleteOwnReport = (reportId, onSuccess) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `standupreports/${reportId}`,
      method: 'DELETE',
    });
    dispatch(DELETE_OWN_REPORT_SUCCESS(data));
    onSuccess();
  } catch (err) {}
};

export const EDIT_MEETING_REPORT_SUCCESS = createAction('reports/EDIT_MEETING_REPORT.SUCCESS');
export const editMeetingReport = (reportData, reportId, onSuccess) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `standupreports/${reportId}`,
      method: 'PUT',
      query: reportData,
    });
    dispatch(EDIT_MEETING_REPORT_SUCCESS(data));
    onSuccess();
  } catch (err) {}
};

export const CHECK_REPORT_PENALTIES = createAction('reports/CHECK_REPORT_PENALTIES');
export const checkReportPenalties = () => async (dispatch) => {
  try {
    await ApiService.apiCall({
      endpoint: 'standupreports/penaltyusers',
      method: 'GET',
    });
    dispatch(CHECK_REPORT_PENALTIES());
  } catch (err) {}
};

export const SET_REPORT_PENALTY = createAction('reports/SET_REPORT_PENALTY');
export const setReportPenalty = (hasPenalty) => (dispatch) => dispatch(SET_REPORT_PENALTY(hasPenalty));
