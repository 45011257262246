import { takeLatest } from 'redux-saga/effects';

import { 
  CLEAR_PROJECT_DATA, 
  SET_PROJECT_ADMINS_SUCCESS, 
  SET_PROJECT_MEMBERS_SUCCESS,
  CHANGE_PROJECT_COLUMNS_SEQUENCE_SUCCESS,
} from '../../../actions/projects';

import onSetProjectMembers from './onSetProjectMembers';
import onSetProjectAdmins from './onSetProjectAdmins';
import onClearProjectData from './onClearProjectData';
import onChangeProjectColumnsSequence from './onChangeProjectColumnsSequence';

export default function* fn() {
  yield takeLatest(SET_PROJECT_MEMBERS_SUCCESS, onSetProjectMembers);
  yield takeLatest(SET_PROJECT_ADMINS_SUCCESS, onSetProjectAdmins);
  yield takeLatest(CHANGE_PROJECT_COLUMNS_SEQUENCE_SUCCESS, onChangeProjectColumnsSequence);
  yield takeLatest(CLEAR_PROJECT_DATA, onClearProjectData);
}
