import { select } from 'redux-saga/effects';
import { RolePermissions } from '../../../../Helpers/RolePermissions';
import { getCurrentUser } from '../../../selectors/auth';
import { setDocBooks } from '../../../actions/docbooks';
import { bookAccess } from '../../../../containers/wiki/config';
import { store } from '../../../index';

function* onFetchAllDocBooks(action) {
  const { dispatch } = store;
  const user = yield select(getCurrentUser);
  const canSeeAllDocBooks = RolePermissions.canSeeAllDocBooks(user.role, user.additionRole);

  if (canSeeAllDocBooks) {
    dispatch(setDocBooks(action.payload));
  } else {
    const docsWithAccess = action.payload.filter((docBook) => {
      const isMemberOfBook = !!docBook.users.find((docUser) => docUser._id === user.id);
      return docBook.status === bookAccess.PUBLIC || isMemberOfBook;
    });
    dispatch(setDocBooks(docsWithAccess));
  }

  yield true;
}

export default onFetchAllDocBooks;
