import { store } from '../../../index';
import { fetchAllProjectTasks } from '../../../actions/projectTasks';
import { projectFiltersStorage } from '../../../../Helpers/storageFunctions';

function* onEditProjectTask(action) {
  const { dispatch } = store;
  const { projectId } = action.payload.data;
  const filters = projectFiltersStorage().getProjectFilter();
  dispatch(fetchAllProjectTasks(projectId, filters.board));

  yield true;
}

export default onEditProjectTask;
