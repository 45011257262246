import { store } from '../../../index';
import { fetchCurrentUser } from '../../../actions/auth';

function* onUploadFilesSuccess() {
  const { dispatch } = store;
  dispatch(fetchCurrentUser());
  yield true;
}

export default onUploadFilesSuccess;
