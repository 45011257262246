import { takeLatest } from 'redux-saga/effects';

import updateTimeLogsMy from './updateTimeLogsMy';
import { CREATE_TIMELOG_SUCCESS, DELETE_TIMELOG_SUCCESS, EDIT_TIMELOG_SUCCESS } from '../../../actions/timeLogs';
import updateTimeLogsMyAfterEdit from './updateTimeLogsMyAfterEdit';
import updateTimeLogsMyAfterCreate from './updateTimeLogsMyAfterCreate';

export default function* fn() {
  yield takeLatest(CREATE_TIMELOG_SUCCESS, updateTimeLogsMyAfterCreate);
  yield takeLatest(EDIT_TIMELOG_SUCCESS, updateTimeLogsMyAfterEdit);
  yield takeLatest(DELETE_TIMELOG_SUCCESS, updateTimeLogsMy);
}
