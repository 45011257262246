import { createReducer } from '@reduxjs/toolkit';
import {
  CLEAR_SELECTED_DOCBOOK,
  CREATE_DOCBOOK_SUCCESS,
  GET_DOCBOOK_BY_ID_SUCCESS,
  SET_DOCBOOKS,
  UPDATE_DOCBOOK_ADMINS_SUCCESS,
  UPDATE_DOCBOOK_MEMBERS_SUCCESS,
} from '../actions/docbooks';

const initialState = {
  docBooks: [],
  docBooksActive: [],
  docBooksArchived: [],
  selectedDocBook: null,
};

const handlers = {
  [CREATE_DOCBOOK_SUCCESS]: (prevState, { payload }) => {
    const prevDocbooks = [...prevState.docBooks];
    const prevDocbooksActive = [...prevState.docBooksActive];
    return {
      ...prevState,
      docBooks: [payload, ...prevDocbooks],
      docBooksActive: [payload, ...prevDocbooksActive],
    };
  },

  [SET_DOCBOOKS]: (prevState, { payload }) => {
    return {
      ...prevState,
      docBooks: payload,
      docBooksActive: payload?.filter((book) => book?.status === 'ACTIVE'),
      docBooksArchived: payload?.filter((book) => book?.status === 'ARCHIVED'),
    };
  },

  [GET_DOCBOOK_BY_ID_SUCCESS]: (prevState, { payload }) => {
    const selectedDoc = payload;
    return {
      ...prevState,
      selectedDocBook: selectedDoc,
    };
  },

  [CLEAR_SELECTED_DOCBOOK]: (prevState) => {
    return {
      ...prevState,
      selectedDocBook: null,
    };
  },

  [UPDATE_DOCBOOK_MEMBERS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      selectedDocBook: {
        ...payload,
      },
    };
  },

  [UPDATE_DOCBOOK_ADMINS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      selectedDocBook: {
        ...payload,
      },
    };
  },
};

export default createReducer(initialState, handlers);
