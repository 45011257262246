import { checkTimeLogPenalties, fetchAllTimeLogs, fetchAllTimeLogsMy } from '../../../actions/timeLogs';
import { format } from 'date-fns';
import {
  fetchTaskById,
  updateBacklogTasksAfterTimelog,
  updateProjectTasksAfterTimelog,
} from '../../../actions/projectTasks';
import { store } from '../../../index';

function* updateTimeLogsMyAfterEdit(action) {
  try {
    const { dispatch, getState } = store;
    const { trackedTimelog, updatedTimeLog } = action.payload.data; // old & updated timelog obj
    const { updateReports } = action.payload;
    const startFromMonday = format(new Date(action.payload.mondayDate), 'yyyy-MM-dd');
    const { projectTasks } = getState();

    const isBacklog = projectTasks.projectBacklog.find((task) => task?._id === updatedTimeLog?.taskId);

    if (isBacklog) {
      const updatedProjectTasks = projectTasks.projectBacklog.map((task) => {
        if (task?._id === updatedTimeLog?.taskId) {
          return { ...task, trackedTime: task.trackedTime + (Number(updatedTimeLog.timelog) - Number(trackedTimelog)) };
        } else {
          return task;
        }
      });
      dispatch(updateBacklogTasksAfterTimelog(updatedProjectTasks));
    } else {
      const updatedProjectTasks = projectTasks.projectTasks.map((task) => {
        if (task?._id === updatedTimeLog?.taskId) {
          return { ...task, trackedTime: task.trackedTime + (Number(updatedTimeLog.timelog) - Number(trackedTimelog)) };
        } else {
          return task;
        }
      });
      dispatch(updateProjectTasksAfterTimelog(updatedProjectTasks));
    }
    
    dispatch(fetchAllTimeLogsMy(startFromMonday, updatedTimeLog.userId));
    dispatch(fetchAllTimeLogs(updateReports));
    dispatch(checkTimeLogPenalties());
    dispatch(fetchTaskById(updatedTimeLog.taskId));

    yield true;
  } catch (err) {}
}

export default updateTimeLogsMyAfterEdit;
