import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.vacations;

export const vacationReviewersSelector = createSelector(getState, (vacations) => vacations?.vacationReviewers);
export const myVacationsSelector = createSelector(getState, (vacations) => vacations?.myVacations);
export const getAllVacationsLoading = createSelector(getState, (vacations) => vacations?.allVacationLoading);
export const allVacationsSelector = createSelector(getState, (vacations) => vacations?.allVacations);
export const getAllVacationsError = createSelector(getState, (vacations) => vacations?.allVacationsError);
export const vacationRequestsSelector = createSelector(getState, (vacations) => vacations?.vacationRequests);
export const getVacationsToReview = createSelector(getState, (vacations) => vacations?.hasVactionsToReview);
export const todayVacationsSelector = createSelector(getState, (vacations) => vacations?.todayVacations);
