import { takeLatest } from 'redux-saga/effects';

import { ADD_HOLIDAY_SUCCESS, DELETE_HOLIDAY_SUCCESS, EDIT_HOLIDAY_SUCCESS } from '../../../actions/holidays';
import updateHolidaysList from './updateHolidaysList';

export default function* fn() {
  yield takeLatest(ADD_HOLIDAY_SUCCESS, updateHolidaysList);
  yield takeLatest(EDIT_HOLIDAY_SUCCESS, updateHolidaysList);
  yield takeLatest(DELETE_HOLIDAY_SUCCESS, updateHolidaysList);
}
