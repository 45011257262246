import { select } from 'redux-saga/effects';
import { fetchAllDocPages } from '../../../actions/docpages';
import { getSelectedDocBook } from '../../../selectors/docbooks';
import { store } from '../../../index';

function* onUpdateDocPages() {
  const { dispatch } = store;
  const selectedDocBook = yield select(getSelectedDocBook);
  dispatch(fetchAllDocPages(selectedDocBook._id));
}

export default onUpdateDocPages;
