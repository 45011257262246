import { store } from '../../../index';
import { select } from 'redux-saga/effects';
import { getAllProjectVersions } from '../../../selectors/projectVersions';
import { editProjectVersion } from '../../../actions/projectVersions';
import { getAllProjectTasks, getCurrentTask } from '../../../selectors/projectTasks';

function* onSelectVersion(action) {
  const { dispatch } = store;
  const allVersions = yield select(getAllProjectVersions);
  const allTasks = yield select(getAllProjectTasks);
  const selectedTask = yield select(getCurrentTask);
  const versionId = action.payload.data ? action.payload.data.versionId : action.payload.versionId;
  const selectedVersion = allVersions.find((el) => el._id === versionId);
  const changeVersionOrAddNewTask =
    selectedTask?.versionId !== versionId || allTasks.every((el) => el._id !== selectedTask?._id);

  if (selectedVersion && changeVersionOrAddNewTask) {
    dispatch(
      editProjectVersion({
        versionId,
        versionData: {
          ...selectedVersion,
          tasksIds: action.payload.data
            ? [...selectedVersion.tasksIds.map((taskIds) => taskIds._id), action.payload.data._id]
            : [...selectedVersion.tasksIds.map((taskIds) => taskIds._id), action.payload._id],
        },
      }),
    );
  }
}

export default onSelectVersion;
