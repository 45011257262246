import { store } from '../../../index';
import { getProjectById } from '../../../actions/projects';

function* onChangeProjectColumnsSequence(action) {
  const { dispatch } = store;
  const projectId = action.payload._id;
  dispatch(getProjectById(projectId));

  yield true;
}

export default onChangeProjectColumnsSequence;