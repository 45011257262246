import { createAction } from '@reduxjs/toolkit';

export const CHANGE_PROJECT_FILTERS = createAction('projects/CHANGE_PROJECT_FILTERS');

export const changeProjectFilters = (payload) => (dispatch) => {
  dispatch(CHANGE_PROJECT_FILTERS(payload));
};

export const RESET_PROJECT_FILTERS = createAction('projects/RESET_PROJECT_FILTERS');

export const resetProjectFilters = () => (dispatch) => {
  dispatch(RESET_PROJECT_FILTERS());
};
