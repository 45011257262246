import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const getProjectSprints = createAsyncThunk(
  'sprints/getProjectSprints',
  async ({ projectId, offset, status }, { rejectWithValue }) => {
    try {
      const filterByStatus = status ? `&status=${status}` : '';

      const { data } = await ApiService.apiCall({
        endpoint: `projects/sprints/project/${projectId}?offset=${offset}${filterByStatus}`,
        method: 'GET',
      });
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  },
);
export const createProjectSprints = createAsyncThunk(
  'sprints/createProjectSprints',
  async ({ sprintData, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `projects/sprints`,
        method: 'POST',
        query: sprintData,
      });
      onSuccess();
      return data;
    } catch (error) {
      onFailure(error.response.data.error);
      return rejectWithValue(error);
    }
  },
);
export const editProjectSprint = createAsyncThunk(
  'sprints/editProjectSprints',
  async ({ sprintId, sprintData, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `projects/sprints/${sprintId}`,
        method: 'PUT',
        query: sprintData,
      });
      if (onSuccess) {
        onSuccess();
      }
      return data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.response.data.error);
      }
      return rejectWithValue(error);
    }
  },
);
export const changeSprintStatus = createAsyncThunk(
  'sprints/changeSprintStatus',
  async ({ sprintId, status, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `projects/sprints/${sprintId}`,
        method: 'PUT',
        query: status,
      });
      onSuccess();
      return data;
    } catch (error) {
      onFailure(error.response.data.error);
      return rejectWithValue(error);
    }
  },
);
export const addTasksToSprint = createAsyncThunk(
  'sprints/addTasksToSprint',
  async ({ sprintId, tasksData, onSuccess, onFailure }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `projects/sprints/${sprintId}/addTask`,
        method: 'PUT',
        query: tasksData,
      });
      dispatch(getProjectSprints({ projectId: tasksData.projectId, offset: '' }));
      onSuccess();
    } catch (error) {
      onFailure(error.response.data.error);
      return rejectWithValue(error);
    }
  },
);
export const deleteTasksFromSprint = createAsyncThunk(
  'sprints/deleteTasksFromSprint',
  async ({ sprintId, tasksData, onSuccess, onFailure }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `projects/sprints/${sprintId}/deleteTask`,
        method: 'PUT',
        query: tasksData,
      });
      onSuccess();
      dispatch(getProjectSprints({ projectId: tasksData.projectId, offset: '' }));
    } catch (error) {
      onFailure(error.response.data.error);
      return rejectWithValue(error);
    }
  },
);
