import { createReducer } from '@reduxjs/toolkit';

import { GET_CANDIDATES_HISTORY_BY_ID_SUCCESS } from '../actions/history';

const initialState = {
  candidatHistory: [],
  totalLength: null,
};

const handlers = {
  [GET_CANDIDATES_HISTORY_BY_ID_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      candidatHistory: payload?.candidateHistory,
      totalLength: payload?.totalLength,
    };
  },
};

export default createReducer(initialState, handlers);
