import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.docbooks;

export const getAllDocBooks = createSelector(getState, ({ docBooks, docBooksActive, docBooksArchived }) => ({
  docBooks,
  docBooksActive,
  docBooksArchived,
}));
export const getSelectedDocBook = createSelector(getState, ({ selectedDocBook }) => selectedDocBook);
