import { createReducer } from '@reduxjs/toolkit';
import { GET_TASKS_HISTORY_BY_ID_SUCCESS, GET_TASKS_HISTORY_BY_ID_PENDING } from '../actions/taskHistory';
import { LOADING } from '../../assets/loading';

const initialState = {
  tasksHistory: [],
  tasksHistoryLength: null,
  loading: LOADING.FAILURE,
};

const handlers = {
  [GET_TASKS_HISTORY_BY_ID_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      tasksHistory: payload.taskHistory,
      tasksHistoryLength: payload.totalLength,
      loading: LOADING.SUCCESS,
    };
  },
  [GET_TASKS_HISTORY_BY_ID_PENDING]: (prevState) => {
    return {
      ...prevState,
      loading: LOADING.PENDING,
    };
  },
};

export default createReducer(initialState, handlers);
