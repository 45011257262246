import { takeLatest } from 'redux-saga/effects';

import { CHECK_REPORT_PENALTIES } from '../../../actions/reports';
import { CHECK_TIMELOG_PENALTIES } from '../../../actions/timeLogs';
import { CHECK_VACATIONS_TO_REVIEW } from '../../../actions/vacations';

import onCheckReportPenalty from './onCheckReportPenalty';
import onCheckTimeLogPenalty from './onCheckTimeLogPenalty';
import onCheckVacationsToReview from './onCheckVacationsToReview';

export default function* fn() {
  yield takeLatest(CHECK_REPORT_PENALTIES + '.SUCCESS', onCheckReportPenalty);
  yield takeLatest(CHECK_TIMELOG_PENALTIES + '.SUCCESS', onCheckTimeLogPenalty);
  yield takeLatest(CHECK_VACATIONS_TO_REVIEW + '.SUCCESS', onCheckVacationsToReview);
}
