import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const GET_ALL_VACANCIES_SUCCESS = createAction('vacancies/GET_ALL_VACANCIES.SUCCESS');
export const fetchAllVacancies =
  (page = '', offset = '', status = '') =>
  async (dispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `vacancies?page=${page}&offset=${offset}&status=${status}`,
      });
      dispatch(GET_ALL_VACANCIES_SUCCESS(data));
    } catch (err) {}
  };
export const GET_TEMPLATE_VACANCIES_SUCCESS = createAction('vacancies/GET_TEMPLATE_VACANCIES.SUCCESS');
export const fetchTemplateVacancies = () => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: 'vacancies?status=TEMPLATE',
    });
    dispatch(GET_TEMPLATE_VACANCIES_SUCCESS(data));
  } catch (err) {}
};

export const SET_VACANCIES = createAction('vacancies/SET_VACANCIES');
export const setVacancies = (vacancies) => (dispatch) => {
  dispatch(SET_VACANCIES(vacancies));
};

export const GET_VACANCY_BY_ID_SUCCESS = createAction('vacancies/GET_VACANCY_BY_ID.SUCCESS');
export const fetchVacancyById = (id) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `vacancies/${id}`,
    });
    dispatch(GET_VACANCY_BY_ID_SUCCESS(data));
  } catch (err) {}
};

export const CREATE_VACANCY_SUCCESS = createAction('vacancies/CREATE_VACANCY.SUCCESS');
export const CREATE_VACANCY_FAILURE = createAction('vacancies/CREATE_VACANCY.FAILURE');
export const createVacancy = (newVacancyData, onSuccess) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'POST',
      endpoint: 'vacancies',
      query: newVacancyData,
    });
    dispatch(CREATE_VACANCY_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(CREATE_VACANCY_FAILURE(err));
  }
};

export const EDIT_VACANCY_SUCCESS = createAction('vacancies/EDIT_VACANCY.SUCCESS');
export const editVacancy = (vacancyId, newVacancyData, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `vacancies/${vacancyId}`,
      query: newVacancyData,
    });
    dispatch(EDIT_VACANCY_SUCCESS(data));
    onSuccess();
  } catch (err) {
    onFailure(err?.resonse?.data?.error);
  }
};

export const CHANGE_VACANCY_STATUS_SUCCESS = createAction('vacancies/CHANGE_VACANCY_STATUS.SUCCESS');
export const CHANGE_VACANCY_STATUS_FAILURE = createAction('vacancies/CHANGE_VACANCY_STATUS.FAILURE');
export const changeVacancyStatus = (vacancyId, status, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `vacancies/status/${vacancyId}`,
      query: status,
    });
    dispatch(CHANGE_VACANCY_STATUS_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(CHANGE_VACANCY_STATUS_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const DELETE_VACANCY_SUCCESS = createAction('vacancies/DELETE_VACANCY.SUCCESS');
export const deleteVacancy = (vacancyId, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'DELETE',
      endpoint: `vacancies/${vacancyId}`,
    });
    dispatch(DELETE_VACANCY_SUCCESS(data));
    onSuccess();
  } catch (err) {
    onFailure(err?.response?.data?.error);
  }
};

export const CLEAR_SELECTED_VACANCY = createAction('vacancies/CLEAR_SELECTED_VACANCY');
export const clearSelectedVacancy = () => (dispatch) => {
  dispatch(CLEAR_SELECTED_VACANCY());
};
