import { createReducer } from '@reduxjs/toolkit';
import {
  GET_MILESTONES_SUCCESS,
  CREATE_MILESTONE_SUCCESS,
  EDIT_MILESTONE_SUCCESS,
  DELETE_MILESTONE_SUCCESS,
  CLEAR_MILESTONES,
  ADD_TASK_IN_MILESTONE_SUCCESS,
  DELETE_TASK_FROM_MILESTONE_SUCCESS,
  ADD_TASK_IN_MILESTONE_FAILURE,
  DELETE_TASK_FROM_MILESTONE_FAILURE,
} from '../actions/projectMilestones';

const initialState = {
  projectMilestones: [],
  error: null,
};

const handlers = {
  [GET_MILESTONES_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      projectMilestones: payload,
    };
  },

  [CREATE_MILESTONE_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      projectMilestones: [...prevState.projectMilestones, payload],
    };
  },

  [EDIT_MILESTONE_SUCCESS]: (prevState, { payload }) => {
    const projectMilestones = [...prevState.projectMilestones];
    projectMilestones.splice(
      projectMilestones.findIndex((item) => item._id === payload._id),
      1,
      payload,
    );

    return {
      ...prevState,
      projectMilestones: [...projectMilestones],
    };
  },

  [DELETE_MILESTONE_SUCCESS]: (prevState, { payload }) => {
    const projectMilestones = [...prevState.projectMilestones];
    const newProjectMilestones = projectMilestones.filter((ms) => ms._id !== payload._id);

    return {
      ...prevState,
      projectMilestones: newProjectMilestones,
    };
  },
  [CLEAR_MILESTONES]: (prevState) => {
    return {
      ...prevState,
      projectMilestones: [],
    };
  },
  [ADD_TASK_IN_MILESTONE_SUCCESS]: (prevState) => {
    return {
      ...prevState,
    };
  },
  [ADD_TASK_IN_MILESTONE_FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      error: payload.message,
    };
  },
  [DELETE_TASK_FROM_MILESTONE_FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      error: payload.message,
    };
  },
  [DELETE_TASK_FROM_MILESTONE_SUCCESS]: (prevState) => {
    return {
      ...prevState,
    };
  },
};

export default createReducer(initialState, handlers);
