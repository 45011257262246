import { editProjectTask, fetchAllProjectTasks } from '../../../actions/projectTasks';
import { store } from '../../../index';

function* onCreateMilestone(action) {
  const { dispatch } = store;
  if (action.payload.specialParameter) {
    dispatch(
      editProjectTask(
        {
          ...action.payload.specialParameter,
          projectId: action.payload.data.projectId,
          milestoneId: action.payload.data._id,
        },
        action.payload.specialParameter._id,
      ),
    );
  }
  dispatch(fetchAllProjectTasks(action.payload.projectId));
  yield true;
}

export default onCreateMilestone;
