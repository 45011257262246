export function getQueryParams(params) {
  /* Convert object to query string (e.g {startDate: '1111-11-11'}) -> 'startDate=1111-11-11' */
  const query = [];
  const filters = Object.entries(params);

  for (let [filterKey, filterValue] of filters) {
    if (filterValue || filterValue === false) {
      query.push(`${filterKey}=${filterValue}`);
    }
  }

  return query.join('&');
}

export function setQueryParams(queryString) {
  /* Convert query string to object (e.g 'startDate=1111-11-11' -> {startDate: '1111-11-11'}) */
  if (!queryString) return null;

  const params = {};
  const query = new URLSearchParams(queryString);

  for (let [key, value] of query.entries()) {
    params[key] = value;
  }

  return params;
}
