import { format } from 'date-fns';

export function tokenStorage() {
  const getAccessToken = () => localStorage.getItem('accessToken');
  const getRefreshToken = () => localStorage.getItem('refreshToken');
  const setAccessToken = (token) => localStorage.setItem('accessToken', token);
  const setRefreshToken = (token) => localStorage.setItem('refreshToken', token);

  return { getAccessToken, getRefreshToken, setAccessToken, setRefreshToken };
}

export function activeProjectsStorage() {
  const getActive = () => JSON.parse(localStorage.getItem('showActiveProjects')) ?? false;
  const setActive = (isActive) => localStorage.setItem('showActiveProjects', JSON.stringify(isActive));

  return { getActive, setActive };
}

export function activeReportProjectsStorage() {
  const getActive = () => JSON.parse(localStorage.getItem('showActiveReportProjects')) ?? false;
  const setActive = (isActive) => localStorage.setItem('showActiveReportProjects', JSON.stringify(isActive));

  return { getActive, setActive };
}

export function activeUsersStorage() {
  const getActive = () => JSON.parse(localStorage.getItem('showActiveUsers')) ?? true;
  const setActive = (isActive) => localStorage.setItem('showActiveUsers', JSON.stringify(isActive));

  return { getActive, setActive };
}

export function selectedProjectIdStorage() {
  const getProjectId = () => JSON.parse(localStorage.getItem('selectedProjectId')) ?? '';
  const setProjectId = (projectId) => localStorage.setItem('selectedProjectId', JSON.stringify(projectId));

  return { getProjectId, setProjectId };
}

export function languageStore() {
  const setLanguage = (lang) => localStorage.setItem('language', lang);
  const getLanguage = () => localStorage.getItem('language');

  return { setLanguage, getLanguage };
}

export function fridayTimelogStatus() {
  const setStatus = (status) =>
    localStorage.setItem('fridayStatus', JSON.stringify({ status, date: format(new Date(), 'yyyy-MM-dd') }));
  const getStatus = () => JSON.parse(localStorage.getItem('fridayStatus'));

  return { setStatus, getStatus };
}

export function toggleHRandRecruitmentMenuStorage() {
  const getHRandRecruitmentMenu = () => JSON.parse(localStorage.getItem('HRandRecruitmentMenu')) ?? '';
  const setHRandRecruitmentMenu = (toggleMenu) =>
    localStorage.setItem('HRandRecruitmentMenu', JSON.stringify(toggleMenu));

  return { getHRandRecruitmentMenu, setHRandRecruitmentMenu };
}

export function toggleReportsMenuStorage() {
  const getReportsMenu = () => JSON.parse(localStorage.getItem('reportsMenu')) ?? '';
  const setReportsMenu = (toggleMenu) => localStorage.setItem('reportsMenu', JSON.stringify(toggleMenu));

  return { getReportsMenu, setReportsMenu };
}

export function manipulateLocalStorage() {
 const getValueFromLocalStorage = (value) =>  JSON.parse(localStorage.getItem(`${value}`)) ?? false;
 const removeValueFromLocalStorage  = (value) => localStorage.removeItem(`${value}`);

  return { getValueFromLocalStorage, removeValueFromLocalStorage };
}

export function projectFiltersStorage() {
  const setProjectFilter = (filters) => localStorage.setItem('savedFilters', JSON.stringify(filters));
  const getProjectFilter = () => JSON.parse(localStorage.getItem('savedFilters')) ?? {};

  return { setProjectFilter, getProjectFilter };
}
