import { store } from '../../../index';
import { getProjectMilestones } from 'Redux/actions/projectMilestones';
import { fetchAllProjectTasks } from 'Redux/actions/projectTasks';
function* onEditMilestone(action) {
  const { dispatch } = store;
  const projectId = action.payload.projectId;
  dispatch(fetchAllProjectTasks(projectId));
  dispatch(getProjectMilestones(projectId));
  yield true;
}
export default onEditMilestone;
