import { takeLatest } from 'redux-saga/effects';

import {
  CREATE_CANDIDATECHANNEL_SUCCESS,
  UPDATE_CANDIDATECHANNEL_SUCCESS,
  DELETE_CANDIDATECHANNEL_SUCCESS,
} from '../../../actions/candidateChannel';

import { onGetCandidateChannel } from './onGetCandidateChannel';

export default function* fn() {
  yield takeLatest(CREATE_CANDIDATECHANNEL_SUCCESS, onGetCandidateChannel);

  yield takeLatest(UPDATE_CANDIDATECHANNEL_SUCCESS, onGetCandidateChannel);

  yield takeLatest(DELETE_CANDIDATECHANNEL_SUCCESS, onGetCandidateChannel);
}
