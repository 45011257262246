import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toggleHRandRecruitmentMenuStorage, toggleReportsMenuStorage } from '../../../Helpers/storageFunctions';
import { Accordion, AccordionDetails, AccordionSummary, useStyles } from './styles';

const AccordionMenu = ({ children, label, panelsNumber, icon, defaultExpanded }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(!!defaultExpanded);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(!!newExpanded);
    if (panel === 'panel1') {
      toggleHRandRecruitmentMenuStorage().setHRandRecruitmentMenu(newExpanded);
    } else {
      toggleReportsMenuStorage().setReportsMenu(newExpanded);
    }
  };

  return (
    <Accordion square expanded={expanded} onChange={handleChange(panelsNumber)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: '#E1E1E1' }} />}
        aria-controls='panel1d-content'
        id='panel1d-header'
      >
        <div className={classes.accordionTitleContainer}>
          <div className={classes.iconContainer}>
            {icon}
          </div>
          <Typography className={classes.accordionTitle}>
            {t(label)}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className='accordion-list'>{children}</div>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionMenu;
