import { getProjectById } from '../../../actions/projects';
import { store } from '../../../index';

function* onSetProjectAdmins(action) {
  const { dispatch } = store;
  const projectId = action.payload._id;
  dispatch(getProjectById(projectId));

  yield true;
}

export default onSetProjectAdmins;
