import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const GENERATE_ONETIME_LINK_SUCCESS = createAction('reset/GENERATE_ONETIME_LINK.SUCCESS');
export const GENERATE_ONETIME_LINK_FAILURE = createAction('reset/GENERATE_ONETIME_LINK.FAILURE');
export const generateOnetimeLink = (email, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'POST',
      endpoint: `reset/one-time-link/generate`,
      query: { email },
    });
    dispatch(GENERATE_ONETIME_LINK_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(GENERATE_ONETIME_LINK_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const VALIDATE_ONE_TIME_LINK_SUCCESS = createAction('reset/VALIDATE_ONE_TIME_LINK.SUCCESS');
export const VALIDATE_ONE_TIME_LINK_FAILURE = createAction('reset/VALIDATE_ONE_TIME_LINK.FAILURE');
export const validateOneTimeLink = (token, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'POST',
      endpoint: `reset/one-time-link/validate`,
      query: { token },
    });
    dispatch(VALIDATE_ONE_TIME_LINK_SUCCESS(data));
  } catch (err) {
    VALIDATE_ONE_TIME_LINK_FAILURE(err);
    onFailure(err?.response?.data?.error);
  }
};

export const RESET_PASSWORD_BY_EMAIL_SUCCESS = createAction('reset/RESET_PASSWORD_BY_EMAIL.SUCCESS');
export const RESET_PASSWORD_BY_EMAIL_FAILURE = createAction('reset/RESET_PASSWORD_BY_EMAIL.FAILURE');
export const resetPasswordByEmail =
  ({ email, password }, onSuccess, onFailure) =>
  async (dispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: 'reset/reset-password',
        query: { email, password },
      });
      dispatch(RESET_PASSWORD_BY_EMAIL_SUCCESS(data));
      onSuccess();
    } catch (err) {
      dispatch(RESET_PASSWORD_BY_EMAIL_FAILURE(err));
      onFailure(err?.response?.data?.error);
    }
  };
