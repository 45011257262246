import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const GET_ALL_PROJECTS_SUCCESS = createAction('projects/GET_ALL_PROJECTS.SUCCESS');
export const fetchAllProjects = (withNonActive, searchName, searchCustomer) => async (dispatch) => {
  try {
    const searchQuery = [];
    let endpoint = 'projects';

    if (withNonActive) {
      searchQuery.push(`withNonActive=${withNonActive}`);
    }

    if (searchName) {
      searchQuery.push(`searchName=${searchName}`);
    }

    if (searchCustomer) {
      searchQuery.push(`searchCustomer=${searchCustomer}`);
    }
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: searchQuery.length > 0 ? `${endpoint}?${searchQuery.join('&')}` : endpoint,
    });

    dispatch(GET_ALL_PROJECTS_SUCCESS(data));
  } catch (err) {}
};

export const GET_ALL_PROJECTS_MY_SUCCESS = createAction('projects/GET_ALL_PROJECTS_MY.SUCCESS');
export const fetchAllProjectsMy = () => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: 'projects/my',
    });
    dispatch(GET_ALL_PROJECTS_MY_SUCCESS(data));
  } catch (err) {}
};

export const GET_PROJECT_BY_ID_SUCCESS = createAction('projects/GET_PROJECT_BY_ID.SUCCESS');
export const getProjectById = (projectId) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `projects/${projectId}`,
      method: 'GET',
      qsParams: { projectId },
    });
    dispatch(GET_PROJECT_BY_ID_SUCCESS(data));
  } catch (err) {}
};
export const CLEAR_SELECTED_PROJECT = createAction('projects/CLEAR_SELECTED_PROJECT');
export const clearSelectedProject = () => (dispatch) => dispatch(CLEAR_SELECTED_PROJECT());

export const GET_PROJECT_BY_CODE_SUCCESS = createAction('projects/GET_PROJECT_BY_CODE.SUCCESS');
export const GET_PROJECT_BY_CODE_FAILURE = createAction('projects/GET_PROJECT_BY_CODE.FAILURE');
export const getProjectByCode = (projectCode, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `projects/project/${projectCode}`,
      method: 'GET',
    });
    dispatch(GET_PROJECT_BY_CODE_SUCCESS(data));
  } catch (err) {
    dispatch(GET_PROJECT_BY_CODE_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const EDIT_PROJECT_SUCCESS = createAction('projects/EDIT_PROJECT.SUCCESS');
export const EDIT_PROJECT_FAILURE = createAction('projects/EDIT_PROJECT.FAILURE');
export const editProject = (projectData, projectId, onSuccess, onFailure, isList) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `projects/${projectId}`,
      method: 'PUT',
      query: projectData,
    });
    dispatch(EDIT_PROJECT_SUCCESS({ data: data, isList: isList }));
    dispatch(fetchAllProjects());
    onSuccess();
  } catch (err) {
    dispatch(EDIT_PROJECT_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const CREATE_PROJECT_SUCCESS = createAction('projects/CREATE_PROJECT.SUCCESS');
export const CREATE_PROJECT_FAILURE = createAction('projects/CREATE_PROJECT.FAILURE');
export const createProject = (projectData, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: 'projects',
      method: 'POST',
      query: projectData,
    });
    dispatch(CREATE_PROJECT_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(CREATE_PROJECT_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const SET_PROJECT_MEMBERS_SUCCESS = createAction('projects/SET_PROJECT_MEMBERS.SUCCESS');
export const setProjectMembers = (projectId, members, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `projects/${projectId}/members`,
      method: 'PUT',
      query: members,
    });
    dispatch(SET_PROJECT_MEMBERS_SUCCESS(data));
    onSuccess();
  } catch (err) {
    onFailure(err?.response?.data?.error);
  }
};
export const SET_PROJECT_ADMINS_SUCCESS = createAction('projects/SET_PROJECT_ADMINS.SUCCESS');
export const setProjectAdmins = (projectId, members, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `projects/${projectId}/admins`,
      method: 'PUT',
      query: members,
    });
    dispatch(SET_PROJECT_ADMINS_SUCCESS(data));
    onSuccess();
  } catch (err) {
    onFailure(err.response.data.error);
  }
};

export const CHANGE_PROJECT_COLUMNS_SEQUENCE_SUCCESS = createAction('projects/CHANGE_PROJECT_COLUMNS_SEQUENCE.SUCCESS');
export const changeProjectColumnsSequence = (columns, projectId, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `projects/${projectId}/columns`,
      query: columns,
    });

    dispatch(CHANGE_PROJECT_COLUMNS_SEQUENCE_SUCCESS(data));
  } catch (error) {
    onFailure(error);
  }
};

export const GET_ACTIVE_PROJECTS_SUCCESS = createAction('projects/GET_ACTIVE_PROJECTS.SUCCESS');
export const fetchActiveProjects = () => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: 'projects/activeprojects',
    });
    dispatch(GET_ACTIVE_PROJECTS_SUCCESS(data));
  } catch (err) {}
};

export const GET_ACTIVE_PROJECTS_MY_SUCCESS = createAction('projects/GET_ACTIVE_PROJECTS_MY.SUCCESS');
export const fetchActiveProjectsMy = () => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: 'projects/activeprojects/my',
    });
    dispatch(GET_ACTIVE_PROJECTS_MY_SUCCESS(data));
  } catch (err) {}
};

export const TOGGLE_PROJECT_AS_STARRED = createAction('projects/TOGGLE_PROJECT_AS_STARRED');
export const toggleStarredProject =
  (projectId, currentUser, currentLocalStorageData, onSuccess) => async (dispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PATCH',
        endpoint: `users/${currentUser}/starred/${projectId}`,
        query: currentLocalStorageData,
      });
      dispatch(TOGGLE_PROJECT_AS_STARRED(data));
      onSuccess();
    } catch (err) {
      // TODO: proper error handling
    }
  };

export const CLEAR_PROJECT_DATA = createAction('projects/CLEAR_PROJECT_DATA');
export const clearProjectData = () => (dispatch) => dispatch(CLEAR_PROJECT_DATA());

export const CONNECT_TO_SLACK = createAction('projects/CONNECT_TO_SLACK');
export const connectToSlack = (projectCode) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `slack/authorize`,
      qsParams: { projectCode },
    });

    dispatch(CONNECT_TO_SLACK(data));
  } catch (error) {}
};
