import { createReducer } from '@reduxjs/toolkit';
import {
  CLEAR_ALL_DOCPAGES,
  GET_SELECTED_DOCPAGE_SUCCESS,
  CLEAR_SELECTED_DOCPAGE,
  GET_ALL_DOCPAGES_SUCCESS,
  GET_DOCPAGE_HISTORY_SUCCESS,
} from '../actions/docpages';

const initialState = {
  docPages: [],
  selectedDocPage: null,
  docPageHistory: null,
};

const handlers = {
  [GET_ALL_DOCPAGES_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      docPages: payload,
    };
  },

  [CLEAR_ALL_DOCPAGES]: (prevState) => {
    return {
      ...prevState,
      docPages: [],
    };
  },

  [GET_SELECTED_DOCPAGE_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      selectedDocPage: payload,
    };
  },

  [CLEAR_SELECTED_DOCPAGE]: (prevState) => {
    return {
      ...prevState,
      selectedDocPage: null,
    };
  },

  [GET_DOCPAGE_HISTORY_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      docPageHistory: payload,
    };
  },
};

export default createReducer(initialState, handlers);
