import { put } from 'redux-saga/effects';
import { tokenStorage } from '../../../../Helpers/storageFunctions';

import { CLEAR_AUTH_ERRORS } from '../../../actions/auth';

function* onSignIn(action) {
  yield put({ type: CLEAR_AUTH_ERRORS });
  const accessToken = action?.payload?.accessToken;
  const refreshToken = action?.payload?.refreshToken;
  tokenStorage().setAccessToken(accessToken);
  tokenStorage().setRefreshToken(refreshToken);

  yield true;
}

export default onSignIn;
