import { createReducer } from '@reduxjs/toolkit';

import {
  GET_ALL_SPECIALIZATIONS_SUCCESS,
  CREATE_SPECIALIZATION_SUCCESS,
  CREATE_SPECIALIZATION_FAILURE,
  UPDATE_SPECIALIZATIO_SUCCESS,
  DELETE_SPECIALIZATION_SUCCESS,
} from '../actions/specializations';

const initialState = {
  specializations: [],
  error: null,
};

const handlers = {
  [GET_ALL_SPECIALIZATIONS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      specializations: payload,
    };
  },

  [UPDATE_SPECIALIZATIO_SUCCESS]: (prevState, { payload }) => {
    const copiedSpecializations = [...prevState.specializations];
    const replacedSpecializations = copiedSpecializations.map((spec) => {
      if (spec._id === payload._id) {
        spec = payload;
      }
      return spec;
    });

    return {
      ...prevState,
      specializations: [...replacedSpecializations],
    };
  },

  [CREATE_SPECIALIZATION_SUCCESS]: (prevState, { payload }) => {
    const prevSpecializations = [...prevState?.specializations];
    const newSpecializations = [payload, ...prevSpecializations];
    return {
      ...prevState,
      specializations: newSpecializations,
    };
  },

  [CREATE_SPECIALIZATION_FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      error: payload.message,
    };
  },

  [DELETE_SPECIALIZATION_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
    };
  },
};

export default createReducer(initialState, handlers);
