import { fetchAllNationalHolidays } from '../../../actions/holidays';
import { store } from '../../../index';

function* updateHolidaysList() {
  const { dispatch } = store;
  const currentYear = new Date().getUTCFullYear();
  dispatch(fetchAllNationalHolidays(currentYear));

  yield true;
}

export default updateHolidaysList;
