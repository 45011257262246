import { store } from '../../../index';
import { getProjectById } from '../../../actions/projects';
import { projectFiltersStorage } from '../../../../Helpers/storageFunctions';
import { fetchAllProjectTasks } from '../../../actions/projectTasks';

function* onDeleteColumn(action) {
  const { dispatch } = store;
  const projectId = action.payload.projectId;
  const filters = projectFiltersStorage().getProjectFilter();

  dispatch(getProjectById(projectId));
  dispatch(fetchAllProjectTasks(projectId, filters.board));

  yield true;
}

export default onDeleteColumn;
