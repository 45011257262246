// the below are a collection of routes divided into different arrays depending on the role access list

const hrStats = ['/statistics'];

export const profileRoute = ['/profile/:type'];

export const commonRoutes = [
  '/dashboard',
  '/meetings',
  '/candidats/:type',
  '/timetracking',
  '/timetracking/:projectCode/:taskNumber',
  '/projects',
  '/project/:projectCode/:taskNumber/:type',
  '/project/:projectCode/:type',
  '/reports',
  '/vacations/:type',
  '/users',
  '/user/:id/:type',
  '/billabilityReports',
  '/benchReports',
  '/wiki/:type',
  '/docbook/:id',
  '/docpage/:id',
  '/:action-docpage/:id',
  '/docpage-history/:id',
  '/requests/:type',
  ...profileRoute,
];

// adminRoutes are associated with the following roles [OWNER, ADMIN, RECRUITER, MANAGER, HR]
export const adminRoutes = [...commonRoutes, ...hrStats, '/reminders-all', '/vacancies', '/vacancy/:id/:type'];

// salesAccEmplRoutes are associated with the following roles [SALES, ACCOUNTANT, EMPLOYEE]
export const salesAccEmplRoutes = [...commonRoutes, ...hrStats];

// contractorRoutes are associated with the following roles [CONTRACTOR]
export const contractorRoutes = commonRoutes.slice(3, 10);

export const routesOpenedInSeparateTabs = ['wiki/active'];
