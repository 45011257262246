import { takeLatest } from 'redux-saga/effects';
import { CREATE_PROJECT_TASK_SUCCESS, EDIT_PROJECT_TASK_SUCCESS } from '../../../actions/projectTasks';
import {
  addTasksToSprint,
  createProjectSprints,
  deleteTasksFromSprint,
  editProjectSprint,
} from '../../../actions/projectSprints';
import onSelectSprint from './onSelectSprint';
import onEditSprint from './onEditSprint';
import onAddTaskToSprint from './onAddTaskToSprint';
import onDeleteTaskFromSprint from './onDeleteTaskFromSprint';
import onCreateSprint from './onCreateSprint';

export default function* fn() {
  yield takeLatest(CREATE_PROJECT_TASK_SUCCESS, onSelectSprint);
  yield takeLatest(EDIT_PROJECT_TASK_SUCCESS, onSelectSprint);
  yield takeLatest(addTasksToSprint.fulfilled, onAddTaskToSprint);
  yield takeLatest(editProjectSprint.fulfilled, onEditSprint);
  yield takeLatest(deleteTasksFromSprint.fulfilled, onDeleteTaskFromSprint);
  yield takeLatest(createProjectSprints.fulfilled, onCreateSprint);
}
