import { store } from '../../../index';
import { getProjectVersions } from 'Redux/actions/projectVersions';
import { fetchAllProjectTasks } from 'Redux/actions/projectTasks';
function* onEditVersion(action) {
  const { dispatch } = store;
  const projectId = action.payload.projectId;
  const offset = action.meta.arg.offset;
  dispatch(
    getProjectVersions({
      projectId,
      offset,
    }),
  );
  dispatch(fetchAllProjectTasks(projectId));
  yield true;
}
export default onEditVersion;
