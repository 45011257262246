import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state?.timeLogs;

export const getAllTeamTimeLogs = createSelector(getState, (logs) => logs?.timeLogs);
export const getLoading = createSelector(getState, (logs) => logs?.timelogsLoading);
export const getAllTimeLogs = createSelector(getState, (logs) => logs?.timeLogsMy);
export const getSumTimeLogs = createSelector(getState, (logs) => logs?.sumTimelogsForDay);
export const getBillabilityStatistics = createSelector(getState, (logs) => logs?.billabilityStatistics);
export const getBenchStatistics = createSelector(getState, (logs) => logs?.benchStatistics);
export const getTimeLogPenalty = createSelector(getState, (logs) => logs?.hasTimeLogPenalty);
export const getTimeLogsMonthStatistics = createSelector(getState, (logs) => logs?.timeLogsMonthStatistics);
export const myTimeLogsWeekStatistics = createSelector(getState, (logs) => logs?.myTimeLogsWeekStatistics);
export const getFridaySubmitStatus = createSelector(getState, (logs) => logs?.fridaySubmitStatus);
export const getTimeLogByTask = createSelector(getState, (logs) => logs?.timeLogsByTask);
export const getAllMyTimelogs = createSelector(getState, (logs) => logs?.timeLogsMy);
export const getAllTimelogsLength = createSelector(getState, (logs) => logs?.timelogsLength);
export const getError = createSelector(getState, (logs) => logs?.error);
