import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getReportPenalty } from '../../../Redux/selectors/reports';
import { getVacationsToReview } from '../../../Redux/selectors/vacations';
import { getFridaySubmitStatus, getTimeLogPenalty } from '../../../Redux/selectors/timeLogs';
import { selectTodayRemindersStatus } from '../../../Redux/selectors/userReminders';
import { useTranslation } from 'react-i18next';
import { warningIcon } from '../sidebar/icons';

const NavBarButton = ({ text, href, docpageRoute, docbookRoute, vacancyRoute, projectRoute, icon }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const fridayStatusSubmitted = useSelector(getFridaySubmitStatus);
  const { reportPenalty, timelogPenalty, vacationsToReview, todayRemindersStatus } = useSelector((state) => ({
    reportPenalty: getReportPenalty(state),
    timelogPenalty: getTimeLogPenalty(state),
    vacationsToReview: getVacationsToReview(state),
    todayRemindersStatus: selectTodayRemindersStatus(state),
  }));

  const current =
    location.pathname.includes(href.split('/')[0]) ||
    location.state?.prevPath.includes(href.split('/')[0]) ||
    location.pathname.includes(docpageRoute) ||
    location.pathname.includes(docbookRoute) ||
    location.pathname.includes(vacancyRoute) ||
    location.pathname.includes(projectRoute);

  const className = current ? 'nav-button current-page' : 'nav-button';

  const hasReportPenalty = href === 'meetings' && reportPenalty;
  const hasTimelogPenalty = href === 'timetracking' && timelogPenalty;
  const hasWeekTraked = href === 'dashboard' && fridayStatusSubmitted;
  const hasVacationToReview = href === 'vacations/my-vacations' && vacationsToReview[0];
  const hasTodayReminders = href === 'reminders-all' && todayRemindersStatus;

  const icons = () => {
    if (hasReportPenalty) {
      return showPenaltyIconSvg(t('dailyReportPenalty'), href);
    } else if (hasTimelogPenalty) {
      return showPenaltyIconSvg(t('trackTimePenalty'), href);
    } else if (hasWeekTraked) {
      return showPenaltyIconSvg(t('weekTimeTraked'), href);
    } else if (hasVacationToReview) {
      return showPenaltyIconSvg(t('vacationToReview'), href);
    } else if (hasTodayReminders) {
      return showPenaltyIconSvg(t('remindersPenalty'), href);
    } else {
      return icon;
    }
  };

  const showPenaltyIconSvg = (message, href) => <>{warningIcon(href, message)}</>;
  return (
    <div className={className}>
      <div className='icon-container'>{icons()}</div>
      <div className='nav-button-text-container'>
        <span title={text}>{text}</span>
      </div>
    </div>
  );
};

export default NavBarButton;
