import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const SIGN_IN_SUCCESS = createAction('auth/SIGN_IN.SUCCESS');
export const SIGN_IN_FAILURE = createAction('auth/SIGN_IN.FAILURE');
export const signIn = (userData) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'POST',
      endpoint: 'auth/login',
      query: {
        user: {
          ...userData,
        },
      },
    });
    dispatch(SIGN_IN_SUCCESS(data));
  } catch (err) {
    dispatch(SIGN_IN_FAILURE(err?.response?.data?.error));
  }
};

export const UPDATE_PASSWORD_SUCCESS = createAction('auth/UPDATE_PASSWORD.SUCCESS');
export const updatePassword =
  ({ ...passData }) =>
  async (dispatch) => {
    try {
      const { data } = ApiService.apiCall({
        method: 'PATCH',
        endpoint: 'users/password/me',
        query: {
          ...passData,
        },
      });
      dispatch(UPDATE_PASSWORD_SUCCESS(data));
    } catch (err) {}
  };

export const GET_CURRENT_USER_SUCCESS = createAction('auth/GET_CURRENT_USER.SUCCESS');
export const fetchCurrentUser = () => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `users/current`,
    });
    dispatch(GET_CURRENT_USER_SUCCESS(data));
  } catch (err) {}
};

export const UPDATE_CURRENT_USER_SUCCESS = createAction('auth/UPDATE_CURRENT_USER.SUCCESS');
export const UPDATE_CURRENT_USER_FAILURE = createAction('auth/UPDATE_CURRENT_USER.FAILURE');
export const updateCurrentUser = (id, userData, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `users/${id}`,
      query: userData,
    });
    onSuccess();
    setTimeout(() => {
      dispatch(UPDATE_CURRENT_USER_SUCCESS(data));
    }, 1000);
  } catch (err) {
    dispatch(UPDATE_CURRENT_USER_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const LOG_OUT = createAction('auth/LOG_OUT');
export const logOut = () => async (dispatch) => {
  try {
    dispatch(LOG_OUT());
  } catch (err) {}
};

export const CLEAR_AUTH_ERRORS = createAction('auth/CLEAR_AUTH_ERRORS');
export const clearAuthErrors = () => (dispatch) => {
  dispatch(CLEAR_AUTH_ERRORS());
};
