import { clearMilestones } from '../../../actions/projectMilestones';
import { clearCurrentTask, clearProjectTasks } from '../../../actions/projectTasks';
import { store } from '../../../index';

function* onClearProjectData() {
  const { dispatch } = store;
  dispatch(clearMilestones());
  dispatch(clearProjectTasks());
  dispatch(clearCurrentTask());
  yield true;
}

export default onClearProjectData;
