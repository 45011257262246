import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export const Accordion = withStyles({
  root: {
    backgroundColor: '#333333',
    color: '#E1E1E1',
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    fontSize: '20px',
    backgroundColor: '#333333',
    paddingRight: 0,
    paddingLeft: 0,
    minHeight: 0,
    transition: 'color 250ms linear',
    '&.Mui-expanded': {
      minHeight: 0,
      margin: 0,
    },
    '&:hover': {
      color: '#a1a1a1',
    },
  },
  content: {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  expandIcon: {
    padding: 0,
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
  },
}))(MuiAccordionDetails);

export const useStyles = makeStyles((theme) => ({
  accordionTitle: {
    fontFamily: '"Montserrat", sans-serif',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '1.3',
    marginLeft: '14px',
  },
  iconContainer: {
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  accordionTitleContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}));
