import { fetchAllVacancies } from '../../../actions/vacancies';
import { store } from '../../../index';

function* updateVacanciesList() {
  const { dispatch } = store;

  dispatch(fetchAllVacancies());

  yield true;
}

export default updateVacanciesList;
