import { store } from '../../../index';
import { increaseUsersLength } from '../../../actions/users';

function* onCreate({ payload }) {
  const { dispatch } = store;
  dispatch(increaseUsersLength());

  yield true;
}

export default onCreate;
