import { takeLatest } from 'redux-saga/effects';

import { CHANGE_VACANCY_STATUS_SUCCESS, EDIT_VACANCY_SUCCESS } from '../../../actions/vacancies';

import updateVacanciesList from './updateVacanciesList';

export default function* fn() {
  yield takeLatest(EDIT_VACANCY_SUCCESS, updateVacanciesList);
  yield takeLatest(CHANGE_VACANCY_STATUS_SUCCESS, updateVacanciesList);
}
