import { takeLatest } from 'redux-saga/effects';
import {
  CREATE_REMINDER_SUCCESS,
  DELETE_REMINDER_SUCCESS,
  UPDATE_REMINDER_SUCCESS,
} from '../../../actions/userReminders';
import { onGetReminders } from './onGetReminders';

export default function* fn() {
  yield takeLatest(CREATE_REMINDER_SUCCESS, onGetReminders);
  yield takeLatest(UPDATE_REMINDER_SUCCESS, onGetReminders);
  yield takeLatest(DELETE_REMINDER_SUCCESS, onGetReminders);
}
