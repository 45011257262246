import { createAction } from '@reduxjs/toolkit';
import { getQueryParams } from '../../Helpers/queryParams';
import { ApiService } from '../middleware/api';

export const GET_ALL_TIMELOGS_SUCCESS = createAction('timelogs/GET_ALL_TIMELOGS.SUCCESS');
export const GET_ALL_TIMELOGS_FAILURE = createAction('timelogs/GET_ALL_TIMELOGS.FAILURE');
export const GET_ALL_TIMELOGS_PENDING = createAction('timelogs/GET_ALL_TIMELOGS.PENDING');

export const fetchAllTimeLogs = (filterQuery) => async (dispatch) => {
  dispatch(GET_ALL_TIMELOGS_PENDING());
  try {
    const query = getQueryParams(filterQuery);
    const { data } = await ApiService.apiCall({
      endpoint: `timelogs?${query}`,
      method: 'GET',
    });
    dispatch(GET_ALL_TIMELOGS_SUCCESS(data));
  } catch (err) {
    dispatch(GET_ALL_TIMELOGS_FAILURE(err));
  }
};
export const CLEAR_PROJECT_TIMELOGS = createAction('projects/CLEAR_PROJECT_TIMELOGS');
export const clearProjectTimelogs = () => (dispatch) => dispatch(CLEAR_PROJECT_TIMELOGS());

export const GET_ALL_TIMELOGS_MY_SUCCESS = createAction('timelogs/GET_ALL_TIMELOGS_MY.SUCCESS');
export const fetchAllTimeLogsMy = (date, userId) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `timelogs/my`,
      qsParams: { startDate: date || null, userId: userId || null },
    });
    dispatch(GET_ALL_TIMELOGS_MY_SUCCESS(data));
  } catch (err) {}
};

export const GET_ALL_SUM_TIMELOGS_SUCCESS = createAction('timelogs/GET_ALL_SUM_TIMELOGS.SUCCESS');
export const fetchAllSumTimeLogs = (filters) => async (dispatch) => {
  try {
    const { startDate = '', endDate = '', isBillable = '', milestoneId = '', projectId = '', userId = '' } = filters;

    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: 'timelogs/sumtimelogs',
      qsParams: {
        startDate: startDate || null,
        endDate: endDate || null,
        isBillable: isBillable || null,
        milestoneId: milestoneId || null,
        projectId: projectId || null,
        userId: userId?.length ? userId.join(',') : null,
      },
    });
    dispatch(GET_ALL_SUM_TIMELOGS_SUCCESS(data));
  } catch (err) {}
};

export const GET_BILLABILITY_STATISTICS_SUCCESS = createAction('timelogs/GET_BILLABILITY_STATISTICS.SUCCESS');
export const fetchBillabilityStatistics = (startDate, endDate, filterByRole) => async (dispatch) => {
  try {
    const endpoint = 'timelogs/billability-report-statistics';
    const endDateQuery = endDate ? `&endDate=${endDate}` : '';

    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `${endpoint}?startDate=${startDate}${endDateQuery}&filteredRole=${filterByRole}`,
    });
    dispatch(GET_BILLABILITY_STATISTICS_SUCCESS(data));
  } catch (err) {}
};
export const GET_BENCH_STATISTICS_SUCCESS = createAction('timelogs/GET_BENCH_STATISTICS.SUCCESS');
export const fetchBenchStatistics = (startDate, endDate, filterByRole) => async (dispatch) => {
  try {
    const endpoint = 'timelogs/bench-report-statistics';
    const endDateQuery = endDate ? `&endDate=${endDate}` : '';

    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `${endpoint}?startDate=${startDate}${endDateQuery}&filteredRole=${filterByRole}`,
    });
    dispatch(GET_BENCH_STATISTICS_SUCCESS(data));
  } catch (err) {}
};

export const CREATE_TIMELOG_SUCCESS = createAction('timelogs/CREATE_TIMELOG.SUCCESS');
export const createTimeLog = (timeLogData, mondayDate, onSuccess) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: 'timelogs',
      method: 'POST',
      query: timeLogData,
    });
    dispatch(CREATE_TIMELOG_SUCCESS({ data, mondayDate: mondayDate.toString() }));
    onSuccess();
  } catch (err) {}
};

export const EDIT_TIMELOG_SUCCESS = createAction('timelogs/EDIT_TIMELOG.SUCCESS');
export const editTimeLog = (timeLogId, timeLogData, mondayDate, onSuccess, updateReports) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `timelogs/${timeLogId}`,
      method: 'PUT',
      query: timeLogData,
    });
    dispatch(EDIT_TIMELOG_SUCCESS({ data, mondayDate: mondayDate.toString(), updateReports }));
    onSuccess();
  } catch (err) {}
};

export const DELETE_TIMELOG_SUCCESS = createAction('timelogs/DELETE_TIMELOG.SUCCESS');
export const deleteTimeLog = (timeLogId, mondayDate, userId, onSuccess, updateReports) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `timelogs/${timeLogId}`,
      method: 'DELETE',
      query: { userId },
    });
    dispatch(DELETE_TIMELOG_SUCCESS({ data, mondayDate: mondayDate.toString(), updateReports }));
    onSuccess();
  } catch (err) {}
};

export const CHECK_TIMELOG_PENALTIES = createAction('timelogs/CHECK_TIMELOG_PENALTIES');
export const checkTimeLogPenalties = () => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: 'timelogs/penaltyusers',
      method: 'GET',
    });
    dispatch(CHECK_TIMELOG_PENALTIES(data));
  } catch (err) {}
};

export const SET_TIMELOG_PENALTY = createAction('timelogs/SET_TIMELOG_PENALTY');
export const setTimeLogPenalty = (hasPenalty) => (dispatch) => {
  dispatch(SET_TIMELOG_PENALTY(hasPenalty));
};

export const GET_TIMELOGS_MONTH_STATICTICS_SUCCESS = createAction('timelogs/GET_TIMELOGS_MONTH_STATICTICS.SUCCESS');
export const fetchTimeLogsMonthStatistics = (date) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `timelogs/monthstatistics?month=${date}`,
      method: 'GET',
    });
    dispatch(GET_TIMELOGS_MONTH_STATICTICS_SUCCESS(data));
  } catch (err) {}
};

export const GET_MY_TIMELOGS_WEEK_STATICTICS_SUCCESS = createAction('timelogs/GET_MY_TIMELOGS_WEEK_STATICTICS.SUCCESS');
export const fetchMyTimeLogsWeekStatistics = (dateStart, userID) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `timelogs/weekstatistics?startDate=${dateStart}&my=${userID}`,
      method: 'GET',
    });
    dispatch(GET_MY_TIMELOGS_WEEK_STATICTICS_SUCCESS(data));
  } catch (err) {}
};

export const FRIDAY_SUBMIT_STATUS = createAction('timelogs/FRIDAY_SUBMIT_STATUS');
export const setFridaySubmitStatus = (status) => (dispatch) => {
  dispatch(FRIDAY_SUBMIT_STATUS(status));
};

export const GET_TIMELOGS_BY_TASK_ID = createAction('timelogs/GET_TIMELOGS_BY_TASK_ID');
export const fetchTimelogsByTaskId = (taskId, offset) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `timelogs/task/${taskId}?offset=${offset}`,
      method: 'GET',
    });
    dispatch(GET_TIMELOGS_BY_TASK_ID(data));
  } catch (err) {}
};
