import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const GET_ALL_NATIONAL_HOLIDAYS_SUCCESS = createAction('holidays/GET_ALL_NATIONAL_HOLIDAYS.SUCCESS');
export const fetchAllNationalHolidays = (year) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `holidays/?year=${year}`,
    });
    dispatch(GET_ALL_NATIONAL_HOLIDAYS_SUCCESS(data));
  } catch (err) {}
};

export const ADD_HOLIDAY_SUCCESS = createAction('holidays/ADD_HOLIDAY.SUCCESS');
export const addHoliday = (holidayData, onSuccess) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'POST',
      endpoint: 'holidays',
      query: holidayData,
    });
    dispatch(ADD_HOLIDAY_SUCCESS(data));
    onSuccess();
  } catch (err) {}
};

export const EDIT_HOLIDAY_SUCCESS = createAction('holidays/EDIT_HOLIDAY.SUCCESS');
export const editHoliday = (id, holidayData, onSuccess) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `holidays/${id}`,
      query: holidayData,
    });
    dispatch(EDIT_HOLIDAY_SUCCESS(data));
    onSuccess();
  } catch (err) {}
};

export const DELETE_HOLIDAY_SUCCESS = createAction('holidays/DELETE_HOLIDAY.SUCCESS');
export const deleteHoliday = (id, onSuccess) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'DELETE',
      endpoint: `holidays/${id}`,
    });
    dispatch(DELETE_HOLIDAY_SUCCESS(data));
    onSuccess();
  } catch (err) {}
};
