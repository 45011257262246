import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const GET_ALL_SPECIALIZATIONS_SUCCESS = createAction('specializations/GET_ALL_SPECIALIZATIONS.SUCCESS');
export const getAllSpecializations = () => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({ method: 'GET', endpoint: `specializations` });
    dispatch(GET_ALL_SPECIALIZATIONS_SUCCESS(data));
  } catch (err) {}
};

export const CREATE_SPECIALIZATION_SUCCESS = createAction('specializations/CREATE_SPECIALIZATION.SUCCESS');
export const CREATE_SPECIALIZATION_FAILURE = createAction('specializations/CREATE_SPECIALIZATION.FAILURE');
export const createSpecialization = (specialization, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'POST',
      endpoint: `specializations`,
      query: specialization,
    });
    dispatch(CREATE_SPECIALIZATION_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(CREATE_SPECIALIZATION_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const UPDATE_SPECIALIZATIO_SUCCESS = createAction('specializations/UPDATE_SPECIALIZATION.SUCCESS');
export const UPDATE_SPECIALIZATIO_FAILURE = createAction('specializations/UPDATE_SPECIALIZATION.FAILURE');
export const updateSpecialization = (specializationID, specialization, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `specializations/${specializationID}`,
      query: specialization,
    });
    dispatch(UPDATE_SPECIALIZATIO_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(UPDATE_SPECIALIZATIO_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const DELETE_SPECIALIZATION_SUCCESS = createAction('specializations/DELETE_SPECIALIZATION.SUCCESS');
export const DELETE_SPECIALIZATION_FAILURE = createAction('specializations/DELETE_SPECIALIZATION.FAILURE');
export const deleteSpecialization = (specializationID, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'DELETE',
      endpoint: `specializations/${specializationID}`,
    });
    dispatch(DELETE_SPECIALIZATION_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(DELETE_SPECIALIZATION_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};
