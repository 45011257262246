import { takeLatest } from 'redux-saga/effects';

import { 
  CREATE_COLUMN_SUCCESS,
  UPDATE_COLUMN_SUCCESS,
  DELETE_COLUMN_SUCCESS
} from '../../../actions/projectBoardColumns';

import onCreateColumn from './onCreateColumn';
import onUpdateColumn from './onUpdateColumn';
import onDeleteColumn from './onDeleteColumn';

export default function* fn() {
  yield takeLatest(CREATE_COLUMN_SUCCESS, onCreateColumn);
  yield takeLatest(UPDATE_COLUMN_SUCCESS, onUpdateColumn);
  yield takeLatest(DELETE_COLUMN_SUCCESS, onDeleteColumn);
}