import { takeLatest } from 'redux-saga/effects';

import {
  CHANGE_DOCBOOK_STATUS_SUCCESS,
  CREATE_DOCBOOK_SUCCESS,
  EDIT_DOCBOOK_SUCCESS,
  GET_ALL_DOCBOOKS_SUCCESS,
} from '../../../actions/docbooks';
import onFetchAllDocBooks from './onFetchAllDocBooks';
import updateDocBooksList from './updateDocBooksList';

export default function* fn() {
  yield takeLatest(GET_ALL_DOCBOOKS_SUCCESS, onFetchAllDocBooks);
  yield takeLatest(CREATE_DOCBOOK_SUCCESS, updateDocBooksList);
  yield takeLatest(EDIT_DOCBOOK_SUCCESS, updateDocBooksList);
  yield takeLatest(CHANGE_DOCBOOK_STATUS_SUCCESS, updateDocBooksList);
}
