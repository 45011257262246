import { checkTimeLogPenalties, fetchAllTimeLogs, fetchAllTimeLogsMy } from '../../../actions/timeLogs';
import { format } from 'date-fns';
import { fetchTaskById } from '../../../actions/projectTasks';
import { store } from '../../../index';

function* updateTimeLogsMy(action) {
  try {
    const { dispatch } = store;
    const { userId, taskId } = action.payload.data;
    const { updateReports } = action.payload;
    const startFromMonday = format(new Date(action.payload.mondayDate), 'yyyy-MM-dd');
    
    dispatch(fetchAllTimeLogsMy(startFromMonday, userId));
    dispatch(checkTimeLogPenalties());
    dispatch(fetchTaskById(taskId));
    dispatch(fetchAllTimeLogs(updateReports));
    yield true;
  } catch (err) {}
}

export default updateTimeLogsMy;
