import { createSlice } from '@reduxjs/toolkit';
import {
  changeVersionStatus,
  createProjectVersion,
  editProjectVersion,
  getProjectVersions,
} from '../actions/projectVersions';

const projectVersions = createSlice({
  name: 'projectVersions',
  initialState: {
    projectVersions: [],
  },
  extraReducers: {
    [getProjectVersions.fulfilled]: (state, { payload }) => {
      state.projectVersions = [...payload];
    },

    [createProjectVersion.fulfilled]: (state, { payload }) => {
      state.projectVersions = [payload, ...state.projectVersions];
    },

    [editProjectVersion.fulfilled]: (state, { payload }) => {
      const projectVersions = [...state.projectVersions];
      projectVersions.splice(
        projectVersions.findIndex((item) => item._id === payload._id),
        1,
        payload,
      );
      state.projectVersions = [...projectVersions];
    },

    [changeVersionStatus.fulfilled]: (state, { payload }) => {
      const projectVersions = [...state.projectVersions];
      projectVersions.splice(
        projectVersions.findIndex((item) => item._id === payload._id),
        1,
        payload,
      );
      state.projectVersions = [...projectVersions];
    },
  },
});
export default projectVersions.reducer;
