import { createReducer } from '@reduxjs/toolkit';

import {
  GET_ALL_REPORTS_SUCCESS,
  CREATE_MEETING_REPORT_SUCCESS,
  CREATE_MEETING_REPORT_FAILURE,
  DELETE_OWN_REPORT_SUCCESS,
  EDIT_MEETING_REPORT_SUCCESS,
  SET_REPORT_PENALTY,
} from '../actions/reports';

const initialState = {
  reports: [],
  error: null,
  hasReportPenalty: false,
};

const handlers = {
  [GET_ALL_REPORTS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      reports: payload,
    };
  },

  [CREATE_MEETING_REPORT_SUCCESS]: (prevState, { payload }) => {
    let prevReports = [...prevState?.reports];
    return {
      ...prevState,
      reports: [payload, ...prevReports],
      error: null,
    };
  },

  [CREATE_MEETING_REPORT_FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      error: payload.meessage,
    };
  },

  [DELETE_OWN_REPORT_SUCCESS]: (prevState, { payload }) => {
    const prevReports = [...prevState?.reports];
    const filteredReports = prevReports?.filter((report) => report._id !== payload._id);
    return {
      ...prevState,
      reports: filteredReports,
    };
  },

  [EDIT_MEETING_REPORT_SUCCESS]: (prevState, { payload }) => {
    const prevReports = [...prevState?.reports];
    const editedReport = prevReports.map((report) => {
      if (report._id === payload._id) {
        report = payload;
      }
      return report;
    });
    return {
      ...prevState,
      reports: editedReport,
    };
  },

  [SET_REPORT_PENALTY]: (prevState, { payload }) => {
    return {
      ...prevState,
      hasReportPenalty: payload,
    };
  },
};

export default createReducer(initialState, handlers);
