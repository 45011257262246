import { createReducer } from '@reduxjs/toolkit';
import { GET_COMMENTS_FOR_USER_SUCCESS } from '../actions/userComments';

const initialState = {
  commentsOfSelectedUser: [],
};

const handlers = {
  [GET_COMMENTS_FOR_USER_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      commentsOfSelectedUser: payload,
    };
  },
};

export default createReducer(initialState, handlers);
