import { createReducer } from '@reduxjs/toolkit';
import {
  CREATE_COMMENTS_FOR_TASKS_SUCCESS,
  CREATE_COMMENTS_FOR_TASKS_FAILURE,
  UPDATE_COMMENTS_FOR_TASKS_SUCCESS,
  UPDATE_COMMENTS_FOR_TASKS_FAILURE,
  DELETE_COMMENTS_FOR_TASKS_SUCCESS,
  DELETE_COMMENTS_FOR_TASKS_FAILURE,
  GET_COMMENTS_FOR_TASKS_SUCCESS,
} from 'Redux/actions/tasksComments';

const initialState = {
  comments: [],
  error: null,
};

const handlers = {
  [GET_COMMENTS_FOR_TASKS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      comments: payload,
    };
  },

  [CREATE_COMMENTS_FOR_TASKS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      comments: [payload, ...prevState.comments],
    };
  },
  [CREATE_COMMENTS_FOR_TASKS_FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      error: payload.message,
    };
  },

  [UPDATE_COMMENTS_FOR_TASKS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      comments: payload,
    };
  },
  [UPDATE_COMMENTS_FOR_TASKS_FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      error: payload.message,
    };
  },

  [DELETE_COMMENTS_FOR_TASKS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      comments: payload,
    };
  },
  [DELETE_COMMENTS_FOR_TASKS_FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      error: payload.message,
    };
  },
};

export default createReducer(initialState, handlers);
