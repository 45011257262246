import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const GET_COMMENTS_FOR_USER_SUCCESS = createAction('userComments/GET_COMMENTS_FOR_USER.SUCCESS');
export const getCommentsForUser = (userId) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `users/comments/user/${userId}`,
    });
    dispatch(GET_COMMENTS_FOR_USER_SUCCESS(data));
  } catch (err) {}
};

export const CREATE_USER_COMMENT_SUCCESS = createAction('userComments/CREATE_USER_COMMENT.SUCCESS');
export const CREATE_USER_COMMENT_FAILURE = createAction('userComments/CREATE_USER_COMMENT.FAILURE');
export const createUserComment = (userId, commentData, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'POST',
      endpoint: `users/comments/`,
      query: { ...commentData, userId },
    });
    dispatch(CREATE_USER_COMMENT_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(CREATE_USER_COMMENT_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const UPDATE_USER_COMMENT_SUCCESS = createAction('userComments/UPDATE_USER_COMMENT.SUCCESS');
export const UPDATE_USER_COMMENT_FAILURE = createAction('userComments/UPDATE_USER_COMMENT.FAILURE');
export const updateUserComment = (commentId, commentData, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `users/comments/${commentId}`,
      query: commentData,
    });
    dispatch(UPDATE_USER_COMMENT_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(UPDATE_USER_COMMENT_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const DELETE_USER_COMMENT_SUCCESS = createAction('userComments/DELETE_USER_COMMENT.SUCCESS');
export const DELETE_USER_COMMENT_FAILURE = createAction('userComments/DELETE_USER_COMMENT.FAILURE');
export const deleteUserComment = (commentId, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'DELETE',
      endpoint: `users/comments/${commentId}`,
    });
    dispatch(DELETE_USER_COMMENT_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(DELETE_USER_COMMENT_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};
