import { createReducer } from '@reduxjs/toolkit';
import { GET_ALL_NATIONAL_HOLIDAYS_SUCCESS } from '../actions/holidays';

const initialState = {
  nationalHolidays: [],
};

const handlers = {
  [GET_ALL_NATIONAL_HOLIDAYS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      nationalHolidays: payload,
    };
  },
};

export default createReducer(initialState, handlers);
