import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const GET_CANDIDATES_HISTORY_BY_ID_SUCCESS = createAction('users/GET_CANDIDATES_HISTORY_BY_ID.SUCCESS');
export const getHistoryByCandidate = (userId) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `candidates-history/candidate/${userId}`,
      method: 'GET',
    });
    dispatch(GET_CANDIDATES_HISTORY_BY_ID_SUCCESS(data));
  } catch (err) {}
};
