import { createReducer } from '@reduxjs/toolkit';
import { LOADING } from '../../assets/loading';

import {
  GET_VACATION_REVIEWERS_SUCCESS,
  GET_TODAY_VACATIONS_SUCCESS,
  GET_ALL_VACATIONS_PENDING,
  GET_ALL_VACATIONS_SUCCESS,
  GET_ALL_VACATIONS_ERROR,
  GET_MY_VACATIONS_SUCCESS,
  GET_VACATIONS_BY_REVIEWER_SUCCESS,
  CHECK_VACATIONS_TO_REVIEW,
  DELETE_VACATION_SUCCESS,
  CREATE_VACATION_SUCCESS,
} from '../actions/vacations';

const initialState = {
  vacationReviewers: [],
  myVacations: [],
  allVacations: null,
  allVacationLoading: LOADING.IDLE,
  allVacationsError: null,
  vacationRequests: [],
  todayVacations: [],
  hasVactionsToReview: false,
};

const handlers = {
  [GET_VACATION_REVIEWERS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      vacationReviewers: payload,
    };
  },

  [GET_TODAY_VACATIONS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      todayVacations: payload,
    };
  },

  [GET_MY_VACATIONS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      myVacations: payload,
    };
  },

  [GET_ALL_VACATIONS_PENDING]: (prevState) => {
    return {
      ...prevState,
      allVacationLoading: LOADING.PENDING,
    };
  },

  [GET_ALL_VACATIONS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      allVacations: payload?.length ? payload : null,
      allVacationLoading: LOADING.SUCCESS,
    };
  },

  [GET_ALL_VACATIONS_ERROR]: (prevState, { error }) => {
    return {
      ...prevState,
      allVacationsError: error,
      allVacationLoading: LOADING.FAILURE,
    };
  },

  [GET_VACATIONS_BY_REVIEWER_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      vacationRequests: payload,
    };
  },
  [CHECK_VACATIONS_TO_REVIEW]: (prevState, { payload }) => {
    return {
      ...prevState,
      hasVactionsToReview: payload,
    };
  },
  [CREATE_VACATION_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      allVacations: [...prevState.allVacations, payload],
    };
  },
  [DELETE_VACATION_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      allVacations: prevState.allVacations.filter((vacation) => vacation._id !== payload._id),
    };
  },
};

export default createReducer(initialState, handlers);
