import { takeLatest } from 'redux-saga/effects';

import { LOG_OUT, SIGN_IN_SUCCESS } from '../../../actions/auth';

import onSignIn from './onSignIn';
import onLogOut from './onLogOut';

export default function* fn() {
  yield takeLatest(SIGN_IN_SUCCESS, onSignIn);
  yield takeLatest(LOG_OUT, onLogOut);
}
