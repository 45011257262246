import { store } from '../../../index';
import { select } from 'redux-saga/effects';
import { getAllProjectSprints } from '../../../selectors/projectSprints';
import { getAllProjectTasks, getCurrentTask } from '../../../selectors/projectTasks';
import { editProjectSprint } from '../../../actions/projectSprints';

function* onSelectSprint(action) {
  const { dispatch } = store;
  const allSprints = yield select(getAllProjectSprints);
  const allTasks = yield select(getAllProjectTasks);
  const selectedTask = yield select(getCurrentTask);
  const sprintId = action.payload.data ? action.payload.data.sprintId : action.payload.sprintId;
  const selectedSprint = allSprints.find((el) => el._id === sprintId);
  const changeSprintOrAddNewTask =
    selectedTask?.sprintId !== sprintId || allTasks.every((el) => el._id !== selectedTask?._id);

  if (selectedSprint && changeSprintOrAddNewTask) {
    dispatch(
      editProjectSprint({
        sprintId,
        sprintData: {
          ...selectedSprint,
          tasksIds: action.payload.data
            ? [...selectedSprint.tasksIds.map((tasksIds) => tasksIds._id), action.payload.data._id]
            : [...selectedSprint?.tasksIds.map((taskIds) => taskIds._id), action.payload._id],
        },
      }),
    );
  }
}

export default onSelectSprint;
