import { takeLatest } from 'redux-saga/effects';
import {
  CREATE_MILESTONE_SUCCESS,
  EDIT_MILESTONE_SUCCESS,
  ADD_TASK_IN_MILESTONE_SUCCESS,
  DELETE_TASK_FROM_MILESTONE_SUCCESS,
} from '../../../actions/projectMilestones';
import onAddTaskInMilestone from './onAddTaskInMilestone';
import onCreateMilestone from './onCreateMilestone';
import onDeleteTaskFromMilestone from './onDeleteTaskFromMilestone';
import onEditMilestone from './onEditMilestone';

export default function* fn() {
  yield takeLatest(CREATE_MILESTONE_SUCCESS, onCreateMilestone);
  yield takeLatest(EDIT_MILESTONE_SUCCESS, onEditMilestone);
  yield takeLatest(ADD_TASK_IN_MILESTONE_SUCCESS, onAddTaskInMilestone);
  yield takeLatest(DELETE_TASK_FROM_MILESTONE_SUCCESS, onDeleteTaskFromMilestone);
}
