import { createReducer } from '@reduxjs/toolkit';

import {
  GET_CLOSED_VACANCIES_STATISTICS_SUCCESS,
  GET_HIRED_EMPLOYEES_STATISTICS_SUCCESS,
  GET_FIRED_EMPLOYEES_STATISTICS_SUCCESS,
  GET_CANDIDATES_STATISTICS_BY_VACANCY_ID_SUCCESS,
  GET_CANDIDATES_STATISTICS_BY_VACANCY_ID_FAILURE,
  GET_VACATIONS_STATISTICS_SUCCESS,
  DELETE_FIRED_EMPLOYEES_STATISTICS_SUCCESS,
  DELETE_HIRED_EMPLOYEES_STATISTICS_SUCCESS,
  GET_USER_BY_CANDIDATES_STATISTIC_ID_SUCCESS,
  CLEAR_CANDIDATES_STATISTICS,
} from '../actions/statistics';

const initialState = {
  closedVacanciesStatistics: [],
  hiredEmployeesStatistics: [],
  firedEmployeesStatistics: [],
  candidatesStatistics: [],
  candidatesStatisticsId: '',
  usersFromCandidatesStatistics: [],
  error: null,
};

const handlers = {
  [GET_CLOSED_VACANCIES_STATISTICS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      closedVacanciesStatistics: payload,
    };
  },

  [GET_HIRED_EMPLOYEES_STATISTICS_SUCCESS]: (prevState, { payload }) => {
    const filteredEmployees = payload.filter((user) => user.role !== 'CONTRACTOR');
    return {
      ...prevState,
      hiredEmployeesStatistics: filteredEmployees,
    };
  },
  [GET_VACATIONS_STATISTICS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      vacationsStatistics: payload,
    };
  },

  [GET_FIRED_EMPLOYEES_STATISTICS_SUCCESS]: (prevState, { payload }) => {
    const filteredEmployees = payload.filter((user) => user.role !== 'CONTRACTOR');
    return {
      ...prevState,
      firedEmployeesStatistics: filteredEmployees,
    };
  },
  [GET_CANDIDATES_STATISTICS_BY_VACANCY_ID_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      candidatesStatistics: payload.statistics,
      candidatesStatisticsId: payload._id,
    };
  },

  [GET_CANDIDATES_STATISTICS_BY_VACANCY_ID_FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      candidatesStatistics: initialState.candidatesStatistics,
      error: payload.message,
    };
  },

  [DELETE_FIRED_EMPLOYEES_STATISTICS_SUCCESS]: (prevState, { payload }) => {
    const prevStatictic = [...prevState.firedEmployeesStatistics];
    const filteredEmployees = prevStatictic.filter((employee) => employee?.userId?._id !== payload);
    return {
      ...prevState,
      firedEmployeesStatistics: filteredEmployees,
    };
  },
  [DELETE_HIRED_EMPLOYEES_STATISTICS_SUCCESS]: (prevState, { payload }) => {
    const prevStatictic = [...prevState.hiredEmployeesStatistics];
    const filteredEmployees = prevStatictic.filter((employee) => employee?.userId?._id !== payload);
    return {
      ...prevState,
      hiredEmployeesStatistics: filteredEmployees,
    };
  },
  [GET_USER_BY_CANDIDATES_STATISTIC_ID_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      usersFromCandidatesStatistics: payload,
    };
  },
  [CLEAR_CANDIDATES_STATISTICS]: (prevState, { payload }) => {
    return {
      ...prevState,
      usersFromCandidatesStatistics: [],
    };
  },
};

export default createReducer(initialState, handlers);
