import { taskStatuses } from '../../../../assets/projectTaskStatus';
import { fetchAllBacklogTasks, fetchAllProjectTasks } from '../../../actions/projectTasks';
import { store } from '../../../index';

function* onCreateProjectTask(action) {
  const { dispatch } = store;
  const { projectId, status } = action.payload;

  status === taskStatuses.BACKLOG
    ? dispatch(fetchAllBacklogTasks(projectId))
    : dispatch(fetchAllProjectTasks(projectId));

  yield true;
}

export default onCreateProjectTask;
