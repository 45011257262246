import { createReducer } from '@reduxjs/toolkit';
import {
  GET_ALL_VACANCIES_SUCCESS,
  GET_TEMPLATE_VACANCIES_SUCCESS,
  GET_VACANCY_BY_ID_SUCCESS,
  CREATE_VACANCY_SUCCESS,
  CREATE_VACANCY_FAILURE,
  CHANGE_VACANCY_STATUS_SUCCESS,
  EDIT_VACANCY_SUCCESS,
  DELETE_VACANCY_SUCCESS,
  CLEAR_SELECTED_VACANCY,
} from '../actions/vacancies';

const initialState = {
  vacancies: [],
  selectedVacancy: null,
  error: null,
  totalLength: null,
  templateVacancies: [],
};

const handlers = {
  [GET_ALL_VACANCIES_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      vacancies: payload.vacancies,
      totalLength: payload.totalLength,
    };
  },

  [GET_TEMPLATE_VACANCIES_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      templateVacancies: payload.vacancies,
    };
  },

  [GET_VACANCY_BY_ID_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      selectedVacancy: payload,
    };
  },

  [EDIT_VACANCY_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
    };
  },

  [CREATE_VACANCY_SUCCESS]: (prevState, { payload }) => {
    const status = prevState?.vacancies[0]?.status;
    const vacancies = payload.status === status ? [payload, ...prevState.vacancies] : [...prevState.vacancies];
    return {
      ...prevState,
      vacancies,
    };
  },

  [CREATE_VACANCY_FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      error: payload.message,
    };
  },

  [CHANGE_VACANCY_STATUS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
    };
  },

  [DELETE_VACANCY_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
    };
  },

  [CLEAR_SELECTED_VACANCY]: (prevState) => {
    return {
      ...prevState,
      selectedVacancy: null,
    };
  },
};

export default createReducer(initialState, handlers);
