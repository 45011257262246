import { createReducer } from '@reduxjs/toolkit';
import { GET_REMINDERS_SUCCESS, 
         GET_TODAY_REMINDERS_STATUS_SUCCESS, 
         GET_CANDIDATES_REMINDERS_SUCCESS } from '../actions/userReminders';

const initialState = {
  reminders: [],
  candidatesReminders: [], 
  totalLength: null,
  todayRemindersStatus: false,
};

const handlers = {
  [GET_REMINDERS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      reminders: payload.reminders,
      totalLength: payload.totalLength,
    };
  },

  [GET_CANDIDATES_REMINDERS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      candidatesReminders: payload,
    };
  },

  [GET_TODAY_REMINDERS_STATUS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      todayRemindersStatus: payload.todayRemindersStatus,
    };
  },
};

export default createReducer(initialState, handlers);
