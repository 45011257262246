import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';

import createSagaMiddleware from 'redux-saga';
import runSagas from './middleware/sagas';

function createMyStore() {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
    devTools: process.env.NODE_ENV === 'development',
  });

  runSagas(sagaMiddleware);
  return store;
}

export const store = createMyStore();
