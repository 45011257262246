import { fetchAllDocBooks } from '../../../actions/docbooks';
import { store } from '../../../index';

function* updateDocBooksList() {
  const { dispatch } = store;
  dispatch(fetchAllDocBooks());

  yield true;
}

export default updateDocBooksList;
