import i18next from 'i18next';

export const bookAccess = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
};

export const bookAccessLabels = () => ({
  PRIVATE: i18next.t('docBookPrivateStatus'),
  PUBLIC: i18next.t('docBookPublicStatus'),
});

export const bookStatuses = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
}

export const bookStatusesLabels = () => ({
  ACTIVE: i18next.t('docPageActiveStatus'),
  ARCHIVED: i18next.t('docPageArchivedStatus'),
});


export const pageStatuses = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
};

export const pageStatusesLabels = () => ({
  ALL: i18next.t('docPageAllStatus'),
  ACTIVE: i18next.t('docPageActiveStatus'),
  ARCHIVED: i18next.t('docPageArchivedStatus'),
});
