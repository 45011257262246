import { checkReportPenalties } from '../../../actions/reports';
import { store } from '../../../index';
function* onUpdateMeetingReports() {
  const { dispatch } = store;
  dispatch(checkReportPenalties());

  yield true;
}

export default onUpdateMeetingReports;
