import { getProjectMilestones } from 'Redux/actions/projectMilestones';
import { fetchAllProjectTasks } from 'Redux/actions/projectTasks';
import { store } from '../../../index';

function* onDeleteTaskFromMilestone(action) {
  const { dispatch } = store;
  dispatch(fetchAllProjectTasks(action.payload.projectId));
  dispatch(getProjectMilestones(action.payload.projectId));
  yield true;
}
export default onDeleteTaskFromMilestone;
