import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const GET_ALL_CANDIDATECHANNEL_SUCCESS = createAction('candidate-channel/GET_ALL_SPECIALIZATIONS.SUCCESS');
export const getAllCandidateChannel = () => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({ method: 'GET', endpoint: `candidate-channel` });
    dispatch(GET_ALL_CANDIDATECHANNEL_SUCCESS(data));
  } catch (err) {}
};

export const CREATE_CANDIDATECHANNEL_SUCCESS = createAction('candidate-channel/CREATE_CANDIDATECHANNEL.SUCCESS');
export const CREATE_CANDIDATECHANNEL_FAILURE = createAction('candidate-channel/CREATE_CANDIDATECHANNEL.FAILURE');
export const createCandidateChannel = (channel, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'POST',
      endpoint: `candidate-channel`,
      query: channel,
    });
    dispatch(CREATE_CANDIDATECHANNEL_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(CREATE_CANDIDATECHANNEL_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const UPDATE_CANDIDATECHANNEL_SUCCESS = createAction('candidate-channel/UPDATE_CANDIDATECHANNEL.SUCCESS');
export const UPDATE_CANDIDATECHANNEL_FAILURE = createAction('candidate-channel/UPDATE_CANDIDATECHANNEL.FAILURE');
export const updateCandidateChannel = (specializationID, specialization, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `candidate-channel/${specializationID}`,
      query: specialization,
    });
    dispatch(UPDATE_CANDIDATECHANNEL_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(UPDATE_CANDIDATECHANNEL_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const DELETE_CANDIDATECHANNEL_SUCCESS = createAction('candidate-channel/DELETE_CANDIDATECHANNEL.SUCCESS');
export const DELETE_CANDIDATECHANNEL_FAILURE = createAction('candidate-channel/DELETE_CANDIDATECHANNEL.FAILURE');
export const deleteCandidateChannel = (specializationID, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'DELETE',
      endpoint: `candidate-channel/${specializationID}`,
    });
    dispatch(DELETE_CANDIDATECHANNEL_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(DELETE_CANDIDATECHANNEL_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};
