import { select } from 'redux-saga/effects';
import { getUserId } from '../../../selectors/auth';
import { setReportPenalty } from '../../../actions/reports';
import { store } from '../../../index';

function* onCheckReportPenalty(action) {
  const { dispatch } = store;
  const currentUserId = yield select(getUserId);
  const hasUserPenalty = action.payload?.find((user) => user._id === currentUserId);

  dispatch(setReportPenalty(!!hasUserPenalty));

  yield true;
}

export default onCheckReportPenalty;
