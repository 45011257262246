import { createTimeLog } from '../../../actions/timeLogs';
import { store } from '../../../index';

function* onCreateTaskWithTimelog(action) {
  const { dispatch } = store;
  const taskId = action.payload.data._id;
  const { timelogData, mondayDate } = action.payload;

  dispatch(createTimeLog({ ...timelogData, taskId }, mondayDate));

  yield true;
}

export default onCreateTaskWithTimelog;
