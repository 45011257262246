import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.projectTasks;

export const getProjectColumnCount = createSelector(getState, (tasks) => tasks.columnCount);
export const getProjectColumnTasks = (index) => createSelector(getState, (tasks) => tasks.projectTasks[index]);
export const getFilteredColumnTasks = (index) => createSelector(getState, (tasks) => tasks.filteredProjectTasks[index]);
export const getAllProjectTasks = createSelector(getState, (tasks) => tasks.projectTasks);
export const getFilteredProjectTasks = createSelector(getState, (tasks) => tasks.filteredProjectTasks);
export const getProjectBacklog = createSelector(getState, (tasks) => tasks.projectBacklog);
export const getCurrentTask = createSelector(getState, (tasks) => tasks.currentTask);
