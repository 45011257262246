import { createReducer } from '@reduxjs/toolkit';
import { GET_COMMENTS_FOR_PROJECT_SUCCESS } from '../actions/projectComments';

const initialState = {
  commentsOfSelectedProject: [],
};

const handlers = {
  [GET_COMMENTS_FOR_PROJECT_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      commentsOfSelectedProject: payload,
    };
  },
};

export default createReducer(initialState, handlers);
