import { store } from '../../../index';
import { fetchAllProjectTasks } from '../../../actions/projectTasks';
import { projectFiltersStorage } from '../../../../Helpers/storageFunctions';

function* onCreateTaskPositions() {
  const { dispatch } = store;
  const { _id: projectId } = store.getState().projects.selectedProject;

  const filters = projectFiltersStorage().getProjectFilter();
  dispatch(fetchAllProjectTasks(projectId, filters.board));

  yield true;
}

export default onCreateTaskPositions;
