import { takeLatest } from 'redux-saga/effects';

import {
  CREATE_SPECIALIZATION_SUCCESS,
  DELETE_SPECIALIZATION_SUCCESS,
  UPDATE_SPECIALIZATIO_SUCCESS,
} from '../../../actions/specializations';

import { onGetSpecializations } from './onGetSpecializations';

export default function* fn() {
  yield takeLatest(CREATE_SPECIALIZATION_SUCCESS, onGetSpecializations);

  yield takeLatest(UPDATE_SPECIALIZATIO_SUCCESS, onGetSpecializations);

  yield takeLatest(DELETE_SPECIALIZATION_SUCCESS, onGetSpecializations);
}
