import { checkTimeLogPenalties, fetchAllTimeLogs, fetchAllTimeLogsMy } from '../../../actions/timeLogs';
import { format } from 'date-fns';
import { fetchTaskById, updateProjectTasksAfterTimelog } from '../../../actions/projectTasks';
import { store } from '../../../index';

function* updateTimeLogsMyAfterCreate(action) {
  try {
    const { dispatch, getState } = store;
    const { trackedTimelog } = action.payload.data;
    const { updateReports } = action.payload;
    const startFromMonday = format(new Date(action.payload.mondayDate), 'yyyy-MM-dd');
    const taskId = trackedTimelog ? trackedTimelog?.taskId : action.payload.data.taskId;
    const timelog = trackedTimelog ? trackedTimelog?.timelog : action.payload.data.timelog;
    const userId = trackedTimelog ? trackedTimelog?.userId : action.payload.data.userId;
    const { projectTasks } = getState();

    const updatedProjectTasks = projectTasks.projectTasks.map((task) => {
      if (task?._id === taskId) {
        return { ...task, trackedTime: task.trackedTime + timelog };
      } else {
        return task;
      }
    });

    dispatch(updateProjectTasksAfterTimelog(updatedProjectTasks));
    dispatch(fetchAllTimeLogsMy(startFromMonday, userId));
    dispatch(checkTimeLogPenalties());
    dispatch(fetchTaskById(taskId));
    dispatch(fetchAllTimeLogs(updateReports));
    yield true;
  } catch (err) {}
}

export default updateTimeLogsMyAfterCreate;
