import { store } from '../../../index';
import { getMyVacations, checkVacationsToReview } from '../../../actions/vacations';

export function* onVacationUpdate() {
  const { dispatch } = store;
  dispatch(checkVacationsToReview());
  dispatch(getMyVacations());

  yield true;
}
