import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const GET_MILESTONES_SUCCESS = createAction('projects/GET_MILESTONES.SUCCESS');
export const getProjectMilestones = (projectId, status) => async (dispatch) => {
  try {
    const filterByStatus = status ? `?status=${status}` : '';

    const { data } = await ApiService.apiCall({
      endpoint: `projects/milestones/project/${projectId}${filterByStatus}`,
      method: 'GET',
    });
    dispatch(GET_MILESTONES_SUCCESS(data));
  } catch (err) {}
};

export const CREATE_MILESTONE_SUCCESS = createAction('projects/CREATE_MILESTONE.SUCCESS');
export const createMilestone = (milestoneData, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `projects/milestones`,
      method: 'POST',
      query: milestoneData,
    });
    dispatch(CREATE_MILESTONE_SUCCESS(data));
    onSuccess();
  } catch (err) {
    onFailure(err?.response?.data?.error);
  }
};

export const EDIT_MILESTONE_SUCCESS = createAction('projects/EDIT_MILESTONE.SUCCESS');
export const editMilestone = (milestoneId, milestoneData, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `projects/milestones/${milestoneId}`,
      method: 'PUT',
      query: milestoneData,
    });
    dispatch(EDIT_MILESTONE_SUCCESS(data));
    onSuccess();
  } catch (err) {
    onFailure(err?.response?.data?.error);
  }
};

export const DELETE_MILESTONE_SUCCESS = createAction('projects/DELETE_MILESTONE.SUCCESS');
export const deleteProjectMilestone = (milestoneId, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `projects/milestones/${milestoneId}`,
      method: 'DELETE',
    });
    dispatch(DELETE_MILESTONE_SUCCESS(data));
    onSuccess();
  } catch (err) {
    onFailure(err?.response?.data?.error);
  }
};
export const ADD_TASK_IN_MILESTONE_SUCCESS = createAction('projects/ADD_TASK_IN_MILESTONE.SUCCESS');
export const ADD_TASK_IN_MILESTONE_FAILURE = createAction('projects/ADD_TASK_IN_MILESTONE.FAILURE');
export const addTasksInMilestone =
  ({ milestoneId, tasksData }, onSuccess, onFailure) =>
  async (dispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `projects/milestones/${milestoneId}/addTask`,
        method: 'PUT',
        query: tasksData,
      });
      dispatch(ADD_TASK_IN_MILESTONE_SUCCESS(data));
      onSuccess();
    } catch (err) {
      dispatch(ADD_TASK_IN_MILESTONE_FAILURE(err));
      onFailure(err?.response?.data?.error);
    }
  };

export const DELETE_TASK_FROM_MILESTONE_SUCCESS = createAction('projects/DELETE_TASK_FROM_MILESTONE.SUCCESS');
export const DELETE_TASK_FROM_MILESTONE_FAILURE = createAction('projects/DELETE_TASK_FROM_MILESTONE.FAILURE');
export const deleteTasksFromMilestone =
  ({ milestoneId, tasksData }, onSuccess, onFailure) =>
  async (dispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `projects/milestones/${milestoneId}/deleteTask`,
        method: 'PUT',
        query: tasksData,
      });
      dispatch(DELETE_TASK_FROM_MILESTONE_SUCCESS(data));
      onSuccess();
    } catch (err) {
      dispatch(DELETE_TASK_FROM_MILESTONE_FAILURE(err));
      onFailure(err?.response?.data?.error);
    }
  };
export const CLEAR_MILESTONES = createAction('projects/CLEAR_MILESTONES');
export const clearMilestones = () => (dispatch) => dispatch(CLEAR_MILESTONES());
