import { createReducer } from '../../Helpers/redux';

import {
  GENERATE_ONETIME_LINK_FAILURE,
  RESET_PASSWORD_BY_EMAIL_FAILURE,
  RESET_PASSWORD_BY_EMAIL_SUCCESS,
  VALIDATE_ONE_TIME_LINK_SUCCESS,
} from '../actions/resetPassword';

import {
  CLEAR_AUTH_ERRORS,
  GET_CURRENT_USER_SUCCESS,
  LOG_OUT,
  SIGN_IN_FAILURE,
  SIGN_IN_SUCCESS,
  UPDATE_CURRENT_USER_SUCCESS,
} from '../actions/auth';

const initialState = {
  error: null,
  currentUser: null,
  resetPasswordInfo: null,
};

const handlers = {
  [SIGN_IN_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      currentUser: payload.user,
    };
  },
  [SIGN_IN_FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      error: payload,
    };
  },
  [GET_CURRENT_USER_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      currentUser: payload,
    };
  },
  [UPDATE_CURRENT_USER_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      currentUser: payload,
    };
  },
  [LOG_OUT]: () => {
    return {
      ...initialState,
    };
  },
  [CLEAR_AUTH_ERRORS]: (prevState) => {
    return {
      ...prevState,
      error: null,
    };
  },

  [GENERATE_ONETIME_LINK_FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      error: payload,
    };
  },

  [VALIDATE_ONE_TIME_LINK_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      resetPasswordInfo: payload,
    };
  },

  [RESET_PASSWORD_BY_EMAIL_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      resetPasswordInfo: null,
    };
  },

  [RESET_PASSWORD_BY_EMAIL_FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      error: payload,
    };
  },
};

export default createReducer(initialState, handlers);
