import { createSlice } from '@reduxjs/toolkit';
import {
  changeSprintStatus,
  createProjectSprints,
  editProjectSprint,
  getProjectSprints,
} from 'Redux/actions/projectSprints';

const projectSprints = createSlice({
  name: 'projectSprints',
  initialState: {
    projectSprints: [],
    projectSprintsLength: null,
  },

  extraReducers: {
    [getProjectSprints.fulfilled]: (state, { payload }) => {
      state.projectSprints = [...payload.allSprints];
      state.projectSprintsLength = payload.totalLength;
    },

    [createProjectSprints.fulfilled]: (state, { payload }) => {
      state.projectSprints = [payload, ...state.projectSprints];
    },

    [editProjectSprint.fulfilled]: (state, { payload }) => {
      const projectSprints = [...state.projectSprints];
      projectSprints.splice(
        projectSprints.findIndex((item) => item._id === payload._id),
        1,
        payload,
      );
      state.projectSprints = [...projectSprints];
    },

    [changeSprintStatus.fulfilled]: (state, { payload }) => {
      const projectSprints = [...state.projectSprints];
      projectSprints.splice(
        projectSprints.findIndex((item) => item._id === payload._id),
        1,
        payload,
      );
      state.projectSprints = [...projectSprints];
    },
  },
});

export default projectSprints.reducer;
