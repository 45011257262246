import { takeLatest } from 'redux-saga/effects';
import {
  CREATE_PROJECT_COMMENT_SUCCESS,
  DELETE_PROJECT_COMMENT_SUCCESS,
  UPDATE_PROJECT_COMMENT_SUCCESS,
} from '../../../actions/projectComments';
import { onGetCommentsForProject } from './onGetCommentsForProject';

export default function* fn() {
  yield takeLatest(CREATE_PROJECT_COMMENT_SUCCESS, onGetCommentsForProject);
  yield takeLatest(UPDATE_PROJECT_COMMENT_SUCCESS, onGetCommentsForProject);
  yield takeLatest(DELETE_PROJECT_COMMENT_SUCCESS, onGetCommentsForProject);
}
