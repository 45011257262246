import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const GET_REMINDERS_SUCCESS = createAction('userReminders/GET_REMINDERS.SUCCESS');
export const getReminders =
  ({ page = '', offset = '', userId = '', date = '' } = {}) =>
  async (dispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `users/reminders/all?page=${page}&offset=${offset}&userId=${userId}&date=${date}`,
      });
      dispatch(GET_REMINDERS_SUCCESS(data));
    } catch (err) {}
  };

export const GET_CANDIDATES_REMINDERS_SUCCESS = createAction('userReminders/GET_CANDIDATES_REMINDERS.SUCCESS');
export const getCandidatesReminders =
  (candidatesIds) =>
  async (dispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `users/reminders/candidates-reminders?userIds=${candidatesIds}`,
      });
      dispatch(GET_CANDIDATES_REMINDERS_SUCCESS(data));
    } catch (err) {}
};

export const GET_TODAY_REMINDERS_STATUS_SUCCESS = createAction('userReminders/GET_TODAY_REMINDERS_STATUS.SUCCESS');
export const getTodayRemindersStatus = () => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `users/reminders/today-reminders`,
    });
    dispatch(GET_TODAY_REMINDERS_STATUS_SUCCESS(data));
  } catch (err) {}
};

export const CREATE_REMINDER_SUCCESS = createAction('userReminders/CREATE_REMINDER.SUCCESS');
export const CREATE_REMINDER_FAILURE = createAction('userReminders/CREATE_REMINDER.FAILURE');
export const createReminder = (reminderData, onSuccess, onFailure, specialParameter) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'POST',
      endpoint: 'users/reminders/',
      query: reminderData,
    });
    dispatch(CREATE_REMINDER_SUCCESS({ data, specialParameter }));
    onSuccess();
  } catch (err) {
    dispatch(CREATE_REMINDER_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};

export const UPDATE_REMINDER_SUCCESS = createAction('userReminders/UPDATE_REMINDER.SUCCESS');
export const UPDATE_REMINDER_FAILURE = createAction('userReminders/UPDATE_REMINDER.FAILURE');
export const updateReminder =
  (reminderData, reminderId, onSuccess, onFailure, specialParameter) => async (dispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: `users/reminders/${reminderId}`,
        query: reminderData,
      });
      dispatch(UPDATE_REMINDER_SUCCESS({ data, specialParameter }));
      onSuccess();
    } catch (err) {
      dispatch(UPDATE_REMINDER_FAILURE(err));
      onFailure(err?.response?.data?.error);
    }
  };

export const DELETE_REMINDER_SUCCESS = createAction('userReminders/DELETE_REMINDER.SUCCESS');
export const DELETE_REMINDER_FAILURE = createAction('userReminders/DELETE_REMINDER.FAILURE');
export const deleteReminder = (reminderId, onSuccess, onFailure, specialParameter) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'DELETE',
      endpoint: `users/reminders/${reminderId}`,
    });
    dispatch(DELETE_REMINDER_SUCCESS({ data, specialParameter }));
    onSuccess();
  } catch (err) {
    dispatch(DELETE_REMINDER_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};
