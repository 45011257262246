import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const GET_COMMENTS_FOR_PROJECT_SUCCESS = createAction('projectComments/GET_COMMENTS_FOR_PROJECT.SUCCESS');
export const getCommentsForProject = (projectId) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({ method: 'GET', endpoint: `projects/comments/project/${projectId}` });
    dispatch(GET_COMMENTS_FOR_PROJECT_SUCCESS(data));
  } catch (err) {}
};

export const CREATE_PROJECT_COMMENT_SUCCESS = createAction('projectComments/CREATE_PROJECT_COMMENT.SUCCESS');
export const CREATE_PROJECT_COMMENT_FAILURE = createAction('projectComments/CREATE_PROJECT_COMMENT.FAILURE');
export const createProjectComment = (projectId, commentData, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'POST',
      endpoint: `projects/comments/`,
      query: { ...commentData, projectId },
    });
    dispatch(CREATE_PROJECT_COMMENT_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(CREATE_PROJECT_COMMENT_FAILURE(err));
    onFailure(err.response.data.error);
  }
};

export const UPDATE_PROJECT_COMMENT_SUCCESS = createAction('projectComments/UPDATE_PROJECT_COMMENT.SUCCESS');
export const UPDATE_PROJECT_COMMENT_FAILURE = createAction('projectComments/UPDATE_PROJECT_COMMENT.FAILURE');
export const updateProjectComment = (commentId, commentData, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `projects/comments/${commentId}`,
      query: commentData,
    });
    dispatch(UPDATE_PROJECT_COMMENT_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(UPDATE_PROJECT_COMMENT_FAILURE(err));
    onFailure(err.response.data.error);
  }
};

export const DELETE_PROJECT_COMMENT_SUCCESS = createAction('projectComments/DELETE_PROJECT_COMMENT.SUCCESS');
export const DELETE_PROJECT_COMMENT_FAILURE = createAction('projectComments/DELETE_PROJECT_COMMENT.FAILURE');
export const deleteProjectComment = (commentId, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'DELETE',
      endpoint: `projects/comments/${commentId}`,
    });
    dispatch(DELETE_PROJECT_COMMENT_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(DELETE_PROJECT_COMMENT_FAILURE(err));
    onFailure(err.response.data.error);
  }
};
