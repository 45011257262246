import { takeLatest } from 'redux-saga/effects';

import {
  CREATE_MEETING_REPORT_SUCCESS,
  DELETE_OWN_REPORT_SUCCESS,
  EDIT_MEETING_REPORT_SUCCESS,
} from '../../../actions/reports';

import onUpdateMeetingReports from './onUpdateMeetingReports';

export default function* fn() {
  yield takeLatest(CREATE_MEETING_REPORT_SUCCESS, onUpdateMeetingReports);
  yield takeLatest(EDIT_MEETING_REPORT_SUCCESS, onUpdateMeetingReports);
  yield takeLatest(DELETE_OWN_REPORT_SUCCESS, onUpdateMeetingReports);
}
