import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const GET_ALL_DOCBOOKS_SUCCESS = createAction('docbooks/GET_ALL_DOCBOOKS.SUCCESS');
export const fetchAllDocBooks = (tags) => async (dispatch) => {
  try {
    const tagsFilter = tags ? `?tags=${tags}` : '';
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `docbooks/${tagsFilter}`,
    });

    dispatch(GET_ALL_DOCBOOKS_SUCCESS(data));
  } catch (err) {}
};

export const SET_DOCBOOKS = createAction('docbooks/SET_DOCBOOKS');
export const setDocBooks = (docBooks) => (dispatch) => {
  dispatch(SET_DOCBOOKS(docBooks));
};

export const GET_DOCBOOK_BY_ID_SUCCESS = createAction('docbooks/GET_DOCBOOK_BY_ID.SUCCESS');
export const fetchDocBookById = (id) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `docbooks/${id}`,
    });
    dispatch(GET_DOCBOOK_BY_ID_SUCCESS(data));
  } catch (err) {}
};

export const CLEAR_SELECTED_DOCBOOK = createAction('docbooks/CLEAR_SELECTED_DOCBOOK');
export const clearSelectedDocBook = () => (dispatch) => {
  dispatch(CLEAR_SELECTED_DOCBOOK());
};

export const UPDATE_DOCBOOK_MEMBERS_SUCCESS = createAction('docbooks/UPDATE_DOCBOOK_MEMBERS.SUCCESS');
export const updateDocBookMembers = (docBookId, members, onSuccess) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `docbooks/${docBookId}/members`,
      query: members,
    });
    dispatch(UPDATE_DOCBOOK_MEMBERS_SUCCESS(data));
    onSuccess();
  } catch (err) {}
};

export const UPDATE_DOCBOOK_ADMINS_SUCCESS = createAction('docbooks/UPDATE_DOCBOOK_ADMINS.SUCCESS');
export const updateDocBookAdmins = (docBookId, admins, onSuccess) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `docbooks/${docBookId}/admins`,
      query: admins,
    });
    dispatch(UPDATE_DOCBOOK_ADMINS_SUCCESS(data));
    onSuccess();
  } catch (err) {}
};

export const CREATE_DOCBOOK_SUCCESS = createAction('docbooks/CREATE_DOCBOOK.SUCCESS');
export const createDocBook = (newDocBookData, onSuccess) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'POST',
      endpoint: 'docbooks',
      query: newDocBookData,
    });
    dispatch(CREATE_DOCBOOK_SUCCESS(data));
    onSuccess();
  } catch (err) {}
};

export const EDIT_DOCBOOK_SUCCESS = createAction('docbooks/EDIT_DOCBOOK.SUCCESS');
export const editDocBook = (bookId, newDocBookData, onSuccess) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `docbooks/${bookId}`,
      query: newDocBookData,
    });
    dispatch(EDIT_DOCBOOK_SUCCESS(data));
    onSuccess();
  } catch (err) {}
};

export const CHANGE_DOCBOOK_STATUS_SUCCESS = createAction('docbooks/CHANGE_DOCBOOK_STATUS.SUCCESS');
export const CHANGE_DOCBOOK_STATUS_FAILURE = createAction('docbooks/CHANGE_DOCBOOK_STATUS.FAILURE');
export const changeDocbookStatus = (bookId, status, onSuccess, onFailure) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `docbooks/status/${bookId}`,
      query: { status },
    });
    dispatch(CHANGE_DOCBOOK_STATUS_SUCCESS(data));
    onSuccess();
  } catch (err) {
    dispatch(CHANGE_DOCBOOK_STATUS_FAILURE(err));
    onFailure(err?.response?.data?.error);
  }
};
