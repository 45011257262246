import { select } from 'redux-saga/effects';
import { getUserId } from '../../../selectors/auth';
import { setVacationsToReview } from '../../../actions/vacations';
import { store } from '../../../index';

function* onCheckVacationsToReview(action) {
  const { dispatch } = store;
  const currentUserId = yield select(getUserId);
  const hasVacationToReview = action.payload?.find((user) => user.reviewers.includes(currentUserId));

  dispatch(setVacationsToReview(!!hasVacationToReview));

  yield true;
}
export default onCheckVacationsToReview;
