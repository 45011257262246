import { createReducer } from '@reduxjs/toolkit';

import {
  GET_ALL_CANDIDATECHANNEL_SUCCESS,
  CREATE_CANDIDATECHANNEL_SUCCESS,
  CREATE_CANDIDATECHANNEL_FAILURE,
  UPDATE_CANDIDATECHANNEL_SUCCESS,
  DELETE_CANDIDATECHANNEL_SUCCESS,
} from '../actions/candidateChannel';

const initialState = {
  candidateChannel: [],
  error: null,
};

const handlers = {
  [GET_ALL_CANDIDATECHANNEL_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      candidateChannel: payload,
    };
  },

  [UPDATE_CANDIDATECHANNEL_SUCCESS]: (prevState, { payload }) => {
    const copiedChannel = [...prevState.candidateChannel];
    const replacedChannels = copiedChannel.map((spec) => {
      if (spec._id === payload._id) {
        spec = payload;
      }
      return spec;
    });

    return {
      ...prevState,
      candidateChannel: [...replacedChannels],
    };
  },

  [CREATE_CANDIDATECHANNEL_SUCCESS]: (prevState, { payload }) => {
    const prevChannel = [...prevState?.candidateChannel];
    const newCandidateChannel = [payload, ...prevChannel];
    return {
      ...prevState,
      candidateChannel: newCandidateChannel,
    };
  },

  [CREATE_CANDIDATECHANNEL_FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      error: payload?.message,
    };
  },

  [DELETE_CANDIDATECHANNEL_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
    };
  },
};

export default createReducer(initialState, handlers);
