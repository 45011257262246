import { getReminders, getTodayRemindersStatus } from '../../../actions/userReminders';
import { store } from '../../../index';

export function* onGetReminders(action) {
  const { dispatch } = store;
  const userId = action.payload.specialParameter.userId;
  const startDate = action.payload.specialParameter.date;

  if (userId && startDate) {
    dispatch(getReminders({
      userId,
      date: startDate
    }));
  } else if (userId) {
    dispatch(getReminders({ userId }));
  } else {
    dispatch(getReminders({ date: startDate }));
  }
  
  dispatch(getTodayRemindersStatus());
  yield true;
}
