import { select } from 'redux-saga/effects';
import { getUserId } from '../../../selectors/auth';
import { setTimeLogPenalty } from '../../../actions/timeLogs';
import { store } from '../../../index';

function* onCheckTimeLogPenalty(action) {
  const { dispatch } = store;
  const currentUserId = yield select(getUserId);
  const hasUserPenalty = action.payload?.find((user) => user === currentUserId);

  dispatch(setTimeLogPenalty(!!hasUserPenalty));

  yield true;
}

export default onCheckTimeLogPenalty;
