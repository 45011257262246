import { createAction } from '@reduxjs/toolkit';

export const CHANGE_CANDIDATES_BOARD_FILTERS = createAction('candidates/CHANGE_CANDIDATES_BOARD_FILTERS');

export const changeCandidatesBoardFilters = (payload) => (dispatch) =>
  dispatch(CHANGE_CANDIDATES_BOARD_FILTERS(payload));

export const CHANGE_CANDIDATES_LIST_FILTERS = createAction('candidates/CHANGE_CANDIDATES_LIST_FILTERS');

export const changeCandidatesListFilters = (payload) => (dispatch) => dispatch(CHANGE_CANDIDATES_LIST_FILTERS(payload));

export const CLEAR_CANDIDATES_BOARD_AND_LIST_FILTERS = createAction(
  'candidates/CLEAR_CANDIDATES_BOARD_AND_LIST_FILTERS',
);
export const clearCandidatesBoardAndListFilters = () => (dispatch) =>
  dispatch(CLEAR_CANDIDATES_BOARD_AND_LIST_FILTERS());
