import { takeLatest } from 'redux-saga/effects';
import {
  CREATE_USER_COMMENT_SUCCESS,
  DELETE_USER_COMMENT_SUCCESS,
  UPDATE_USER_COMMENT_SUCCESS,
} from '../../../actions/userComments';
import { onGetCommentsForUser } from './onGetCommentsForUser';

export default function* fn() {
  yield takeLatest(CREATE_USER_COMMENT_SUCCESS, onGetCommentsForUser);
  yield takeLatest(UPDATE_USER_COMMENT_SUCCESS, onGetCommentsForUser);
  yield takeLatest(DELETE_USER_COMMENT_SUCCESS, onGetCommentsForUser);
}
