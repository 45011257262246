import { takeLatest } from 'redux-saga/effects';
import { CREATE_PROJECT_TASK_SUCCESS, EDIT_PROJECT_TASK_SUCCESS } from '../../../actions/projectTasks';
import {
  addTasksToVersion,
  createProjectVersion,
  deleteTasksFromVersion,
  editProjectVersion,
} from 'Redux/actions/projectVersions';
import onSelectVersion from './onSelectVersion';
import onEditVersion from './onEditVersion';
import onAddTaskToVersion from './onAddTaskToVersion';
import onDeleteTaskFromVersion from './onDeleteTaskFromVersion';
import onCreateVersion from './onCreateVersion';

export default function* fn() {
  yield takeLatest(CREATE_PROJECT_TASK_SUCCESS, onSelectVersion);
  yield takeLatest(EDIT_PROJECT_TASK_SUCCESS, onSelectVersion);
  yield takeLatest(editProjectVersion.fulfilled, onEditVersion);
  yield takeLatest(addTasksToVersion.fulfilled, onAddTaskToVersion);
  yield takeLatest(deleteTasksFromVersion.fulfilled, onDeleteTaskFromVersion);
  yield takeLatest(createProjectVersion.fulfilled, onCreateVersion);
}
