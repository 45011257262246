import { createTheme } from '@mui/material/styles';
import { font } from '../styles/commonStyles';

const theme = createTheme({
  palette: { primary: { main: '#3f51b5' }, secondary: { main: '#f50057' } },
  typography: {
    mainFont: font.fontFamily,
  },
});

export default theme;
