import { ReactComponent as Dashboard } from './icons/dashboard.svg';
import { ReactComponent as StandUp } from './icons/standup.svg';
import { ReactComponent as Timetracking } from './icons/time.svg';
import { ReactComponent as BillabilityReports } from './icons/billability.svg';
import { ReactComponent as TimeTrackingReports } from './icons/timetrackingreports.svg';
import { ReactComponent as BenchReports } from './icons/benchreports.svg';
import { ReactComponent as Projects } from './icons/projects.svg';
import { ReactComponent as Team } from './icons/team.svg';
import { ReactComponent as Candidats } from './icons/candidates.svg';
import { ReactComponent as Reports } from './icons/reports.svg';
import { ReactComponent as Wiki } from './icons/wiki.svg';
import { ReactComponent as Vacations } from './icons/vacations.svg';
import { ReactComponent as Reminders } from './icons/reminders.svg';
import { ReactComponent as Vacancies } from './icons/vacancies.svg';
import { ReactComponent as HRMenu } from './icons/hrmenu.svg';
import { ReactComponent as HRStatistics } from './icons/dashboardstatistics.svg';

import { ReactComponent as TimetrackingWarning } from './icons/timetrackingreportswarning.svg';
import { ReactComponent as StandUpWarning } from './icons/standupwarning.svg';
import Badge from '@mui/material/Badge';
import { Tooltip } from '@mui/material';

export function getIcon(route) {
  switch (route) {
    case 'dashboard':
      return <Dashboard className='icon' width={17} height={17} />;
    case 'meetings':
      return <StandUp className='icon' width={20} height={20} />;
    case 'timetracking':
      return <Timetracking className='icon' width={20} height={20} />;
    case 'billabilityReports':
      return <BillabilityReports className='icon' width={18} height={18} />;
    case 'benchReports':
      return <BenchReports className='icon' width={20} height={20} />;
    case 'timeTrackingReports':
      return <TimeTrackingReports className='icon' width={20} height={20} />;
    case 'projects':
      return <Projects className='icon' width={18} height={18} />;
    case 'users':
      return <Team className='icon' width={18} height={18} />;
    case 'candidats':
      return <Candidats className='icon' width={16} height={16} />;
    case 'reports':
      return <Reports className='icon' width={18} height={18} />;
    case 'wiki':
      return <Wiki className='icon' width={18} height={18} />;
    case 'vacations':
      return <Vacations className='icon' width={18} height={18} />;
    case 'reminders':
      return <Reminders className='icon' width={22} height={22} />;
    case 'vacancies':
      return <Vacancies className='icon' width={22} height={22} />;
    case 'HRMenu':
      return <HRMenu className='icon' width={22} height={22} />;
    case 'hrStatistics':
      return <HRStatistics className='icon' width={22} height={22} />;
    default:
      return null;
  }
}

export function warningIcon(route, message) {
  switch (route) {
    case 'dashboard':
      return (
        <Tooltip title={message}>
          <Badge color='secondary' overlap='circular' badgeContent=' ' variant='dot'>
            <Dashboard className='icon' width={18} height={18} />
          </Badge>
        </Tooltip>
      );
    case 'meetings':
      return (
        <Tooltip title={message}>
          <StandUpWarning className='icon' width={18} height={18} />
        </Tooltip>
      );
    case 'timetracking':
      return (
        <Tooltip title={message}>
          <TimetrackingWarning className='icon' width={18} height={18} />
        </Tooltip>
      );
    case 'vacations/my-vacations':
      return (
        <Tooltip title={message}>
          <Badge color={'secondary'} overlap='circular' badgeContent=' ' variant='dot'>
            <Vacations className='icon' width={18} height={18} />
          </Badge>
        </Tooltip>
      );
    case 'reminders-all':
      return (
        <Tooltip title={message}>
          <Badge color='secondary' overlap='circular' badgeContent=' ' variant='dot'>
            <Reminders className='icon' width={18} height={18} />
          </Badge>
        </Tooltip>
      );
    default:
      return null;
  }
}
