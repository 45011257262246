import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const getProjectVersions = createAsyncThunk(
  'versions/getProjectVersions',
  async ({ projectId, status, name }, { rejectWithValue }) => {
    try {
      const filterByName = name ? `&name=${name}` : '';
      const filterByStatus = status ? `status=${status}` : '';

      const { data } = await ApiService.apiCall({
        endpoint: `projects/versions/project/${projectId}?${filterByStatus}${filterByName}`,
        method: 'GET',
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const createProjectVersion = createAsyncThunk(
  'versions/createProjectVersions',
  async ({ versionData, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `projects/versions`,
        method: 'POST',
        query: versionData,
      });
      onSuccess();
      return data;
    } catch (error) {
      onFailure(error?.response?.data?.error);
      return rejectWithValue(error);
    }
  },
);
export const editProjectVersion = createAsyncThunk(
  'versions/editProjectVersions',
  async ({ versionId, versionData, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `projects/versions/${versionId}`,
        method: 'PUT',
        query: versionData,
      });
      if (onSuccess) {
        onSuccess();
      }
      return data;
    } catch (error) {
      if (onFailure) {
        onFailure(error?.response?.data?.error);
      }
      return rejectWithValue(error);
    }
  },
);
export const changeVersionStatus = createAsyncThunk(
  'versions/changeVersionsStatus',
  async ({ versionId, status, onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `projects/versions/${versionId}`,
        method: 'PUT',
        query: status,
      });
      onSuccess();
      return data;
    } catch (error) {
      onFailure(error?.response?.data?.error);
      return rejectWithValue(error);
    }
  },
);
export const addTasksToVersion = createAsyncThunk(
  'versions/addTasksToVersion',
  async ({ versionId, tasksData, onSuccess, onFailure }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `projects/versions/${versionId}/addTask`,
        method: 'PUT',
        query: tasksData,
      });
      onSuccess();
      dispatch(getProjectVersions({ projectId: tasksData.projectId }));
    } catch (error) {
      onFailure(error?.response?.data?.error);
      return rejectWithValue(error);
    }
  },
);
export const deleteTasksFromVersion = createAsyncThunk(
  'versions/deleteTasksFromVersion',
  async ({ versionId, tasksData, onSuccess, onFailure }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await ApiService.apiCall({
        endpoint: `projects/versions/${versionId}/deleteTask`,
        method: 'PUT',
        query: tasksData,
      });
      onSuccess();
      dispatch(getProjectVersions({ projectId: tasksData.projectId }));
    } catch (error) {
      onFailure(error?.response?.data?.error);
      return rejectWithValue(error);
    }
  },
);
