export const columnStatus = {
  ADDED: 'ADDED', 
  UPDATED: 'UPDATED', 
  DELETED: 'DELETED'
};

export const columnStatusActionMsg = {
  ADDED: 'columnAddSuccessMsg',
  UPDATED: 'columnUpdateSuccessMsg',
  DELETED: 'columnDeleteSuccessMsg'
}