import React, { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentUser, getUserAdditionRole } from '../../../Redux/selectors/auth';

import NavBarButton from '../navbar-button';
import AccordionMenu from '../accordion-menu';
import { getIcon } from './icons';
import { useTranslation } from 'react-i18next';
import { recruitmentAndHRRoutes, ReportsRoutes, RolePermissions } from '../../../Helpers/RolePermissions';
import { checkReportPenalties } from '../../../Redux/actions/reports';
import { checkTimeLogPenalties } from '../../../Redux/actions/timeLogs';
import { checkVacationsToReview } from '../../../Redux/actions/vacations';
import { getTodayRemindersStatus } from '../../../Redux/actions/userReminders';
import { toggleHRandRecruitmentMenuStorage, toggleReportsMenuStorage } from '../../../Helpers/storageFunctions';
import { routesOpenedInSeparateTabs } from '../../../assets/routes';

const firstBlockLinks = {
  dashboard: <SidebarLink key={1} route='dashboard' label='dashboardSection' icon={getIcon('dashboard')} />,
  meetings: <SidebarLink key={2} route='meetings' label='meetingsSection' icon={getIcon('meetings')} />,
  timetracking: <SidebarLink key={3} route='timetracking' label='timeTrackingSection' icon={getIcon('timetracking')} />,
};

const secondBlockLinks = {
  projects: (
    <SidebarLink key={4} route='projects' label='projectsSection' projectRoute='project' icon={getIcon('projects')} />
  ),
  users: <SidebarLink key={5} route='users' label='teamSection' icon={getIcon('users')} />,
  vacations: (
    <SidebarLink key={6} route='vacations/my-vacations' label='vacationsSection' icon={getIcon('vacations')} />
  ),
  wiki: (
    <SidebarLink
      key={7}
      route='wiki/active'
      docpageRoute='docpage'
      docbookRoute='docbook'
      label='wikiSection'
      className='wiki-link'
      icon={getIcon('wiki')}
    />
  ),
};

const recruitmentAndHRLinks = {
  statistics: (
    <SidebarLink
      key={8}
      route='statistics'
      label='hrStatisticsSection'
      icon={getIcon('hrStatistics')}
      className='HR-link'
    />
  ),
  candidats: (
    <SidebarLink
      key={9}
      route='candidats/board'
      label='candidatsSection'
      icon={getIcon('candidats')}
      className={`${'HR-link'} ${'HR-link-candidats'}`}
    />
  ),
  vacancies: (
    <SidebarLink
      key={10}
      route='vacancies'
      vacancyRoute='vacancy'
      label='vacanciesSection'
      icon={getIcon('vacancies')}
      className={`${'HR-link'} ${'HR-link-vacancies'}`}
    />
  ),
  reminders: (
    <SidebarLink
      key={11}
      route='reminders-all'
      label='remindersSection'
      icon={getIcon('reminders')}
      className={`${'HR-link'} ${'HR-link-reminders'}`}
    />
  ),
};

const reportsLinks = {
  reports: (
    <SidebarLink
      key={11}
      route='reports'
      label='timeTrackingReportSection'
      icon={getIcon('timeTrackingReports')}
      className='report-link'
    />
  ),
  billabilityReports: (
    <SidebarLink
      key={12}
      route='billabilityReports'
      label='billabilityReportsSection'
      icon={getIcon('billabilityReports')}
      className='report-link'
    />
  ),
  benchReports: (
    <SidebarLink
      key={13}
      route='benchReports'
      label='benchReportsSection'
      icon={getIcon('benchReports')}
      className='report-link'
    />
  ),
};

const Sidebar = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const userAdditionRole = useSelector(getUserAdditionRole);

  const rotesListWithAccess = useCallback(
    (linksList) => RolePermissions.canAccessSections(user?.role, userAdditionRole).map((route) => linksList[route]),
    [user],
  );
  const recruitmentAndHRBlockWithAccess = useCallback(
    () =>
      RolePermissions.canAccessSections(user?.role, userAdditionRole).some((route) =>
        recruitmentAndHRRoutes.includes(route),
      ),
    [user],
  );
  const reportsBlockWithAccess = useCallback(
    () =>
      RolePermissions.canAccessSections(user?.role, userAdditionRole).some((route) => ReportsRoutes.includes(route)),
    [user],
  );

  const rotesWithAccess = useMemo(() => {
    return (
      <div className='nav-buttons-list'>
        {rotesListWithAccess(firstBlockLinks).length !== 0 && (
          <div className='nav-buttons-list-block'>{rotesListWithAccess(firstBlockLinks)}</div>
        )}
        {rotesListWithAccess(secondBlockLinks).length !== 0 && (
          <div className='nav-buttons-list-block'>{rotesListWithAccess(secondBlockLinks)}</div>
        )}
        {recruitmentAndHRBlockWithAccess() && (
          <div className='link-menu-HR'>
            <AccordionMenu
              label='RecruitmentHRSection'
              panelsNumber='panel1'
              icon={getIcon('HRMenu')}
              defaultExpanded={toggleHRandRecruitmentMenuStorage().getHRandRecruitmentMenu()}
            >
              {rotesListWithAccess(recruitmentAndHRLinks)}
            </AccordionMenu>
          </div>
        )}
        {reportsBlockWithAccess() && (
          <div className='link-menu-HR'>
            <AccordionMenu
              label='reportsSection'
              panelsNumber='panel2'
              icon={getIcon('reports')}
              defaultExpanded={toggleReportsMenuStorage().getReportsMenu()}
            >
              {rotesListWithAccess(reportsLinks)}
            </AccordionMenu>
          </div>
        )}
      </div>
    );
  }, [rotesListWithAccess]);

  useEffect(() => {
    dispatch(checkReportPenalties());
    dispatch(checkTimeLogPenalties());
    dispatch(checkVacationsToReview());
    dispatch(getTodayRemindersStatus());
  }, []);

  return (
    <div className='main-container'>
      <div className='sidebar-container'>
        <div>
          <div className='logo-container'>
            <Link to='/dashboard'>
              <span className='logo'>OmiTeam</span>
            </Link>
          </div>
          {rotesWithAccess}
        </div>
        <div className='sidebar-app-version'>v0.24.3 beta</div>
      </div>
      {children}
    </div>
  );
};

function SidebarLink({ route, docpageRoute, docbookRoute, vacancyRoute, projectRoute, label, className, icon }) {
  const { t } = useTranslation();
  return (
    <Link
      key={route}
      to={`/${route}`}
      className={`navbar-link ${className ? className : ''}`}
      target={routesOpenedInSeparateTabs.includes(route) ? '_blank' : null}
    >
      <NavBarButton
        text={t(label)}
        href={route}
        docpageRoute={docpageRoute}
        docbookRoute={docbookRoute}
        vacancyRoute={vacancyRoute}
        projectRoute={projectRoute}
        icon={icon}
      />
    </Link>
  );
}

export default Sidebar;
