import { createReducer } from '@reduxjs/toolkit';
import { LOADING } from 'assets/loading';
import {
  GET_ALL_TIMELOGS_SUCCESS,
  CLEAR_PROJECT_TIMELOGS,
  GET_ALL_TIMELOGS_PENDING,
  GET_ALL_TIMELOGS_FAILURE,
  GET_ALL_TIMELOGS_MY_SUCCESS,
  GET_ALL_SUM_TIMELOGS_SUCCESS,
  GET_BILLABILITY_STATISTICS_SUCCESS,
  GET_BENCH_STATISTICS_SUCCESS,
  SET_TIMELOG_PENALTY,
  GET_TIMELOGS_MONTH_STATICTICS_SUCCESS,
  GET_MY_TIMELOGS_WEEK_STATICTICS_SUCCESS,
  GET_TIMELOGS_BY_TASK_ID,
  FRIDAY_SUBMIT_STATUS,
} from '../actions/timeLogs';

const initialState = {
  timeLogs: [],
  timeLogsMy: [],
  sumTimelogsForDay: [],
  billabilityStatistics: [],
  benchStatistics: [],
  hasTimeLogPenalty: false,
  error: null,
  timeLogsMonthStatistics: null,
  myTimeLogsWeekStatistics: [],
  fridaySubmitStatus: false,
  timeLogsByTask: [],
  timelogsLength: null,
  timelogsLoading: LOADING.FAILURE,
};

const handlers = {
  [GET_ALL_TIMELOGS_PENDING]: (prevState, { payload }) => {
    return {
      ...prevState,
      timelogsLoading: LOADING.PENDING,
    };
  },
  [GET_ALL_TIMELOGS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      timeLogs: payload,
      timelogsLoading: LOADING.SUCCESS,
      error: null,
    };
  },
  [CLEAR_PROJECT_TIMELOGS]: (prevState, { payload }) => {
    return {
      ...prevState,
      timeLogs: [],
    };
  },
  [GET_ALL_TIMELOGS_FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      error: payload.message,
      timelogsLoading: LOADING.FAILURE,
    };
  },
  [GET_ALL_TIMELOGS_MY_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      timeLogsMy: payload,
    };
  },
  [GET_ALL_SUM_TIMELOGS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      sumTimelogsForDay: payload,
    };
  },
  [GET_BILLABILITY_STATISTICS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      billabilityStatistics: payload,
    };
  },
  [GET_BENCH_STATISTICS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      benchStatistics: payload,
    };
  },
  [SET_TIMELOG_PENALTY]: (prevState, { payload }) => {
    return {
      ...prevState,
      hasTimeLogPenalty: payload,
    };
  },
  [GET_TIMELOGS_MONTH_STATICTICS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      timeLogsMonthStatistics: payload,
    };
  },
  [GET_MY_TIMELOGS_WEEK_STATICTICS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      myTimeLogsWeekStatistics: payload,
    };
  },
  [FRIDAY_SUBMIT_STATUS]: (prevState, { payload }) => {
    return {
      ...prevState,
      fridaySubmitStatus: payload,
    };
  },
  [GET_TIMELOGS_BY_TASK_ID]: (prevState, { payload }) => {
    return {
      ...prevState,
      timeLogsByTask: payload,
      timelogsLength: payload.length > 20 ? payload.length : prevState.timelogsLength,
    };
  },
};

export default createReducer(initialState, handlers);
