import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const GET_ALL_DOCPAGES_SUCCESS = createAction('docpages/GET_ALL_DOCPAGES.SUCCESS');
export const fetchAllDocPages = (docBookId) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `docpage/docbook/${docBookId}`,
    });
    dispatch(GET_ALL_DOCPAGES_SUCCESS(data));
  } catch (err) {}
};

export const CLEAR_ALL_DOCPAGES = createAction('docpages/CLEAR_ALL_DOCPAGES');
export const clearAllDocPages = () => (dispatch) => {
  dispatch(CLEAR_ALL_DOCPAGES());
};

export const GET_SELECTED_DOCPAGE_SUCCESS = createAction('docpages/GET_SELECTED_DOCPAGE.SUCCESS');
export const fetchSelectedDocPage = (docPageId) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `docpage/${docPageId}`,
    });
    dispatch(GET_SELECTED_DOCPAGE_SUCCESS(data));
  } catch (err) {}
};

export const CLEAR_SELECTED_DOCPAGE = createAction('docpages/CLEAR_SELECTED_DOCPAGE');
export const clearSelectedDocPage = () => (dispatch) => {
  dispatch(CLEAR_SELECTED_DOCPAGE());
};

export const CREATE_DOCPAGE_SUCCESS = createAction('docpages/CREATE_DOCPAGE.SUCCESS');
export const createDocPage = (newDocPageData, onSuccess) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'POST',
      endpoint: 'docpage',
      query: newDocPageData,
    });
    dispatch(CREATE_DOCPAGE_SUCCESS(data));
    onSuccess();
  } catch (err) {}
};

export const EDIT_DOCPAGE_SUCCESS = createAction('docpages/EDIT_DOCPAGE.SUCCESS');
export const editDocPage = (docPageId, newDocPageData, onSuccess) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `docpage/${docPageId}`,
      query: newDocPageData,
    });
    dispatch(EDIT_DOCPAGE_SUCCESS(data));
    onSuccess();
  } catch (err) {}
};

export const CHANGE_DOCPAGE_STATUS_SUCCESS = createAction('docpages/CHANGE_DOCPAGE_STATUS.SUCCESS');
export const changeDocPageStatus = (docPageId, status, onSuccess) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'PUT',
      endpoint: `docpage/status/${docPageId}`,
      query: { status },
    });
    dispatch(CHANGE_DOCPAGE_STATUS_SUCCESS(data));
    onSuccess();
  } catch (err) {}
};

export const GET_DOCPAGE_HISTORY_SUCCESS = createAction('docpages/GET_DOCPAGE_HISTORY.SUCCESS');
export const fetchDocPageHistory = (docPageId) => async (dispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `pagehistory/${docPageId}`,
    });
    dispatch(GET_DOCPAGE_HISTORY_SUCCESS(data));
  } catch (err) {}
};
